import {
    SET_ASSEMBLY_HAZARD, CLEAR_ASSEMBLY_HAZARD
} from "../../actions/assemblyApp";

const _null = null;

export default (state = _null, { type, hazard }) => {
    Object.freeze(state);
    switch (type) {
        case SET_ASSEMBLY_HAZARD:
            return hazard;
        case CLEAR_ASSEMBLY_HAZARD:
            return _null
        default:
            return state;
    }
};