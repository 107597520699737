import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    reduceSerial,
    reduceLocation,
    reduceSize,
    reduceMake,
    reduceModel, 
    reduceHazard,
    reduceType,
    reduceTestType
} from "../../actions/assemblyApp"

import {
    requestAssembly
} from "../../actions/getter"

function Edit(props) {
    const serial_number = useSelector((state) => state.assembly_serial)
    const location = useSelector((state) => state.assembly_location)
    const size = useSelector((state) => state.assembly_size) ;
    const make = useSelector((state) => state.assembly_make)
    const model = useSelector((state) => state.assembly_model);
    const hazard = useSelector((state) => state.assembly_hazard);
    const type = useSelector((state) => state.assembly_type);
    const testType = useSelector((state) => state.test_type)
    const assemblyID = useSelector((state) => state.assembly_id)
    const dispatch = useDispatch()
    const [show,setShow] = useState(false)

    useEffect(() => {
        if (assemblyID != null) {
            dispatch(requestAssembly(assemblyID)).then((data, err) => {
                setShow(true)
            }) 
        } else {
            setShow(false)
        }
    }, [assemblyID])


    return (
        <Container>
            <Row xs={1} sm md lg={1}>
                {
                    show ?
                        <>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text> Serial Number </InputGroup.Text>
                                    <Form.Control
                                        value={serial_number}
                                        onChange={
                                            (event) => {
                                                dispatch(reduceSerial(event.target.value))
                                            }
                                        }
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text> Location </InputGroup.Text>
                                    <Form.Control
                                        value={location}
                                        onChange={
                                            (event) => {
                                                dispatch(reduceLocation(event.target.value))
                                            }
                                        }
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text> Make </InputGroup.Text>
                                    <Form.Control
                                        value={make}
                                        onChange={
                                            (event) => {
                                                dispatch(reduceMake(event.target.value))
                                            }
                                        }
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text> Model </InputGroup.Text>
                                    <Form.Control
                                        value={model}
                                        onChange={
                                            (event) => {
                                                dispatch(reduceModel(event.target.value))
                                            }
                                        }
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text> Size </InputGroup.Text>
                                    <Form.Select
                                        value={size}
                                        onChange={
                                            (event) => {
                                                dispatch(reduceSize(event.target.value))
                                            }
                                        }
                                    >
                                        <option value={null}> select a size </option>
                                        <option value="3/8">3/8"</option>
                                        <option value="1/2">1/2"</option>
                                        <option value="3/4">3/4"</option>
                                        <option value="1">1"</option>
                                        <option value="1 1/4"> 1 1/4"</option>
                                        <option value="1.5">1 1/2"</option>
                                        <option value="2">2"</option>
                                        <option value="2.5">2 1/2"</option>
                                        <option value="3">3"</option>
                                        <option value="4">4"</option>
                                        <option value="6">6"</option>
                                        <option value="8">8"</option>
                                        <option value="10">10"</option>
                                        <option value="12">12"</option>
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text> Type </InputGroup.Text>
                                    <Form.Select
                                        value={type}
                                        onChange={
                                            (event) => {
                                                dispatch(reduceType(event.target.value))
                                            }
                                        }
                                    >
                                        <option value={null}>select a type </option>
                                        <option value="DC"> DC</option>
                                        <option value="DCDA"> DCDA </option>
                                        <option value="DCDAII"> DCDA-II </option>
                                        <option value="RP"> RP </option>
                                        <option value="RPDA"> RPDA </option>
                                        <option value="RPDAII"> RPDA-II</option>
                                        <option value="PVB"> PVB </option>
                                        <option value="SVB"> SVB </option>
                                        <option value="AVB"> AVB </option>
                                        <option value="AG"> AG </option>
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text> Hazard </InputGroup.Text>
                                    <Form.Control
                                        value={hazard}
                                        onChange={
                                            (event) => {
                                                dispatch(reduceHazard(event.target.value))
                                            }
                                        }
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text> Test Type </InputGroup.Text>
                                    <Form.Select
                                        value={testType}
                                        onChange={
                                            (event) => {
                                                dispatch(reduceTestType(event.target.value))
                                            }
                                        }>
                                        <option value={null} > Select one </option>
                                        <option value="E"> Existing </option>
                                        <option value="NEW"> New </option>
                                        <option value="REPLACED"> Replacement </option>
                                        <option value="RM"> Removed </option>
                                    </Form.Select>
                                </InputGroup>
                            </Col>

                        </>
                        : 
                        <>
                            <Spinner />
                        </>


                }
               
            </Row>
            
        </Container>
    )
}

export default function Assembly() {

    return (
        <Container>
            <Edit />         
        </Container>
    )
}