export const requestReportDetails =  (reportID) =>(
	fetch("backflow/report" + new URLSearchParams({
		reportID: reportID

	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
);




export const requestStopsRoute= (date) => (
	fetch("backflow/stopsapp/stopsroute?" + new URLSearchParams({
		date : date
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})

)
export const requestStoplist = (date) => (
	fetch("backflow/stopsapp/stops?" + new URLSearchParams({
		date : date
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})

)


export const requestAddressDetails = (addressID) => (
	fetch("backflow/stopsapp/addressDetails?" + new URLSearchParams({
		addressID: addressID
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const requestStopServices = (stopID) => (
	fetch("backflow/stopsapp/servicelist?" + new URLSearchParams({
		stopID: stopID
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)
export const requestAssemblyDetails = (assemblyID) => (
	fetch("backflow/stopsapp/assembly?" + new URLSearchParams({
		assemblyID: assemblyID
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const createAssembly = (stopID, addressID) => (
	fetch("backflow/stopsapp/assembly" , {
		method: "PUT",
		body: JSON.stringify({ stopID : stopID, addressID: addressID}), 
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const updateStopState = (stopID, newState, reason) => (
	fetch("backflow/stopsapp/stopstate" , {
		method: "PUT",
		body: JSON.stringify({ stopID : stopID, newState: newState, reason: reason}), 
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const updateServiceState = (truefalse, reason, serviceID, applyToAll) => (
	fetch("backflow/stopsapp/servicestate" , {
		method: "PUT",
		body: JSON.stringify({ ready: truefalse, reason: reason, serviceID: serviceID, applyToAll:  applyToAll}), 
		headers: {
			"Content-Type": "application/json"
		}
	})
)

