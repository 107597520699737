


export const SET_TARGET = "SET_TARGET"
export const CLEAR_TARGET = "CLEAR_TARGET"


const setTarget = (target) => ({ type: SET_TARGET, target })
const clearTarget = () => ({ type: CLEAR_TARGET })

export const reduceTarget = (target) => dispatch => {
	return new Promise((resolve, reject) => {
		dispatch(setTarget(target))
		resolve()
	})
}

export const clearPad = () => dispatch => {
	return new Promise((resolve, reject) => {
		dispatch(clearTarget())
		resolve()
	})
}


export const pendingUpdatedValue = () => { }
export const updateTargetElement = () => { }
	