export const SET_DISC = "SET_DISC"
export const SET_GUIDE = "SET_GUIDE"
export const SET_SPRING = "SET_SPRING"
export const SET_DIAPHRAGM = "SET_DIAPHRAGM"
export const SET_ORING = "SET_ORING"
export const SET_SEAT = "SET_SEAT"
export const SET_MODULE = "SET_MODULE"
export const SET_CHECK_DISC = "SET_CHECK_DISC"
export const SET_FLOAT = "SET_FLOAT"
export const SET_AI_DISC = "SET_AI_DISC"
export const SET_AI_SPRING = "SET_AI_SPRING"
export const SET_CHECK_SPRING = "SET_CHECK_SPRING"
export const SET_RUBBER_KIT = "SET_RUBBER_KIT"

export const CLEAR_DISC = "CLEAR_DISC"
export const CLEAR_GUIDE = "CLEAR_GUIDE"
export const CLEAR_SPRING = "CLEAR_SPRING"
export const CLEAR_DIAPHRAGM = "CLEAR_DIAPHRAGM"
export const CLEAR_ORING = "CLEAR_ORING"
export const CLEAR_SEAT = "CLEAR_SEAT"
export const CLEAR_MODULE = "CLEAR_MODULE"
export const CLEAR_CHECK_DISC = "CLEAR_CHECK_DISC"
export const CLEAR_FLOAT = "CLEAR_FLOAT"
export const CLEAR_AI_DISC = "CLEAR_AI_DISC"
export const CLEAR_AI_SPRING = "CLEAR_AI_SPRING"
export const CLEAR_CHECK_SPRING = "CLEAR_CHECK_SPRING"
export const CLEAR_RUBBER_KIT = "CLEAR_RUBBER_KIT"

const clearDisc = () => ({ type: CLEAR_DISC })
const clearGuide = () => ({ type: CLEAR_GUIDE })
const clearSpring = () => ({ type: CLEAR_SPRING })
const clearDiaph = () => ({ type: CLEAR_DIAPHRAGM })
const clearOring = () => ({ type: CLEAR_ORING })
const clearSeat = () => ({ type: CLEAR_SEAT })
const clearModule = () => ({ type: CLEAR_MODULE })
const clearCheckDisc = () => ({ type: CLEAR_CHECK_DISC })
const clearFloat = () => ({ type: CLEAR_FLOAT })
const clearAiDisc = () => ({ type: CLEAR_AI_DISC })
const clearAiSpring = () => ({ type: CLEAR_AI_SPRING })
const clearCheckSpring = () => ({ type: CLEAR_CHECK_SPRING })
const clearRubberKit = () => ({ type: CLEAR_RUBBER_KIT })

export const clearParts = () => dispatch => {
    return new Promise((resolve, reject) => {
        let p1 = dispatch(clearDisc())
        let p2 = dispatch(clearGuide())
        let p3 = dispatch(clearSpring())
        let p4 = dispatch(clearDiaph())
        let p5 = dispatch(clearOring())
        let p6 = dispatch(clearSeat())
        let p7 = dispatch(clearModule())
        let p8 = dispatch(clearCheckDisc())
        let p9 = dispatch(clearFloat())
        let p10 = dispatch(clearAiDisc())
        let p11 = dispatch(clearAiSpring())
        let p12 = dispatch(clearCheckSpring())
        let p13 = dispatch(clearRubberKit())
        Promise.all([
            p1, p2, p3, p4, p5, p6, p7, p8, p9,
            p10, p11, p12, p13
        ]).then((data, err) => {
            resolve()
        })
    })
}

const setDisc = (truefalse) => ({ type: SET_DISC, truefalse})
const setGuide = (truefalse) => ({ type: SET_GUIDE, truefalse})
const setSpring = (truefalse) => ({ type: SET_SPRING, truefalse})
const setDiaph = (truefalse) => ({ type: SET_DIAPHRAGM, truefalse })
const setOring = (truefalse) => ({ type: SET_ORING, truefalse })
const setSeat = (truefalse) => ({ type: SET_SEAT, truefalse })
const setModule = (truefalse) => ({ type: SET_MODULE, truefalse })
const setCheckDisc = (truefalse) => ({ type: SET_CHECK_DISC, truefalse })
const setFloat = (truefalse) => ({ type: SET_FLOAT, truefalse })
const setAiDisc = (truefalse) => ({ type: SET_AI_DISC, truefalse })
const setAiSpring = (truefalse) => ({ type: SET_AI_SPRING, truefalse })
const setCheckSpring = (truefalse) => ({ type: SET_CHECK_SPRING, truefalse })
const setRubberKit = (truefalse) => ({ type: SET_RUBBER_KIT, truefalse })

export const reduceCheckSpring = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setCheckSpring(truefalse))
        resolve()
    })
}
export const reduceRubberKit = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setRubberKit(truefalse))
        resolve()
    })
}
export const reduceFloat = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFloat(truefalse))
        resolve()
    })
}
export const reduceAiDisc = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setAiDisc(truefalse))
        resolve()
    })
}

export const reduceAiSpring = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setAiSpring(truefalse))
        resolve()
    })
}
export const reduceModule = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setModule(truefalse))
        resolve()
    })
}
export const reduceCheckDisc = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setCheckDisc(truefalse))
        resolve()
    })
}
export const reduceDisc = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setDisc(truefalse))
        resolve()
    })
}
export const reduceGuide = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setGuide(truefalse))
        resolve()
    })
}
export const reduceSpring = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setSpring(truefalse))
        resolve()
    })
}
export const reduceOring = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setOring(truefalse))
        resolve()
    })
}
export const reduceDiaph = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setDiaph(truefalse))
        resolve()
    })
}
export const reduceSeat = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setSeat(truefalse))
        resolve()
    })
}