import {
  SET_ADDRESS_ID, CLEAR_ADDRESS_ID
} from "../../actions/stopsApp";

const _nullID = null; 
             
export default (state = _nullID, { type, id }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ADDRESS_ID:
      return id;
    case CLEAR_ADDRESS_ID: 
      return _nullID
    default:
      return state;
  }
};