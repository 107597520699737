import {
  SET_STOP_LIST, CLEAR_STOP_LIST
} from "../../actions/stopsApp";


const _nullList = []; 
             
export default (state = _nullList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case SET_STOP_LIST:
      return list;
    case CLEAR_STOP_LIST: 
      return _nullList
    default:
      return state;
  }
};