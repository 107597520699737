

export const SET_SO1 = "SET_SO1"
export const SET_SO1_RUSTED = "SET_SO1_RUSTED"
export const SET_SO2 = "SET_SO2"
export const SET_SO2_RUSTED = "SET_SO2_RUSTED"
export const SET_UPSTREAM = "SET_UPSTREAM"
export const SET_UPSTREAM_RUSTED = "SET_UPSTREAM_RUSTED"
export const SET_COMMENT = "SET_COMMENT"

export const CLEAR_SO1 = "CLEAR_SO1"
export const CLEAR_SO1_RUSTED = "CLEAR_SO1_RUSTED"
export const CLEAR_SO2 = "CLEAR_SO2"
export const CLEAR_SO2_RUSTED = "CLEAR_SO2_RUSTED"
export const CLEAR_UPSTREAM = "CLEAR_UPSTREAM"
export const CLEAR_UPSTREAM_RUSTED = "CLEAR_UPSTREAM_RUSTED"
export const CLEAR_COMMENT = "CLEAR_COMMENT"


/*CLEAR UP*/
const clearSO1 = () => ({ type: CLEAR_SO1 })
const clearSO1Rusted = () => ({ type: CLEAR_SO1_RUSTED })
const clearSO2 = () => ({ type: CLEAR_SO2 })
const clearSO2Rusted = () => ({ type: CLEAR_SO2_RUSTED })
const clearUpstream = () => ({ type: CLEAR_UPSTREAM })
const clearUpstreamRusted = () => ({ type: CLEAR_UPSTREAM_RUSTED })
const clearComment = () => ({ type: CLEAR_COMMENT })
export const clearRemarks = () => dispatch => {
    return new Promise((resolve, reject) => {
        let p1 = dispatch(clearSO1())
        let p2 = dispatch(clearSO2())
        let p3 = dispatch(clearUpstream())
        let p4 = dispatch(clearComment())
        let p5 = dispatch(clearSO1Rusted())
        let p6 = dispatch(clearSO2Rusted())
        let p7 = dispatch(clearUpstreamRusted())
        Promise.all([p1, p2, p3, p4, p5, p6, p7]).then((data, err) => {
            resolve()
        })
    })
}

/* SETTERS */
const setSO1 = (truefalse) => ({ type: SET_SO1, truefalse })
const setSO2 = (truefalse) => ({ type: SET_SO2, truefalse })
const setUpstream = (truefalse) => ({ type: SET_UPSTREAM, truefalse })
const setUpstreamRusted = (truefalse) => ({ type: SET_UPSTREAM_RUSTED, truefalse })
const setSO1Rusted = (truefalse) => ({ type: SET_SO1_RUSTED, truefalse })
const setSO2Rusted = (truefalse) => ({ type: SET_SO2_RUSTED, truefalse })
const setComment = (comment) => ({ type: SET_COMMENT, comment })

export const reduceSO1 = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setSO1(truefalse))
        resolve()
    })
}
export const reduceSO2 = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setSO2(truefalse))
        resolve()
    })
}
export const reduceComment = (comment) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setComment(comment))
        resolve()
    })
}
export const reduceUpstream = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setUpstream(truefalse))
        resolve()
    })
}

export const reduceSO1Rusted = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setSO1Rusted(truefalse))
        resolve()
    })
}
export const reduceSO2Rusted = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setSO2Rusted(truefalse))
        resolve()
    })
}
export const reduceUpstreamRusted = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setUpstreamRusted(truefalse))
        resolve()
    })
}