import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reduceStartPoint, reduceEndPoint, reduceRoute} from "../../actions/map"
import {
    getTesterAddressList, requestDefaultAddress
} from "../../actions/settings"

function AddressList(props) {
    const dispatch = useDispatch() 
    const  start = useSelector((state) => state.startpoint)
    const df = useSelector( (state) => state.default_address)
    const routed = useSelector( (state) => state.routed) 

    useEffect(()=>{ 
        dispatch (  requestDefaultAddress()) .then((data, err) =>{
            //console.log("This is the default address: ", data)
        })
    }, [])

    return (
        <Row>  {
                df ?
                    <>
                        <Col>
                            <Badge bg ="secondary">  From:  {df.label}  at {df.street} </Badge> 
                        </Col>
                        <Col>
                            {
                                routed ?
                                    <Badge bg ="secondary"> Day Routed </Badge>
                                : 
                                    <Badge bg = "secondary"> </Badge>

                            }
                        </Col>
                    </>
                : 
                    <> </>


            } 
        </Row>
    )
}

export default function StartEnd() {
    const [show, setShow] = useState(false)
    const [selectType, setSelectType ] = useState('START'); 
    const startAddress = useSelector( (state) => state.startpoint)
    const endAddress = useSelector((state) => state.endpoint )
    const dispatch = useDispatch()
    const date = useSelector((state) => state.dispatchDate )

    useEffect(()=>{
        dispatch( reduceStartPoint(null) )
        dispatch( reduceEndPoint(null ) )
    }, [date])


    return (
        <Container fluid>
            <Row>
                <Col>
                     <AddressList  selectType = { selectType}/>
                </Col>
                { /*<Col >
           
                     {   
                            startAddress ?
                                <FloatingLabel label= "Start" size = "sm">
                                <Form.Control  size = "sm" readOnly value = { `${startAddress.label} `} onClick={
                            () => {
                                setSelectType("START")
                                setShow(true) 
                            }}/>
                                </FloatingLabel>
                            : 
                                <FloatingLabel label = "Start" size = "sm">
                                  <Form.Control  size= "sm" readOnly value = { ``} onClick={
                                        () => {
                                            setSelectType("START")
                                            setShow(true) 
                                        }}/>
                                </FloatingLabel>
                        }
            
                </Col>*/ } 
             {/*   <Col >
                
                    {   
                            endAddress ?
                                <FloatingLabel label= "End">
                                <Form.Control  readOnly value = { `${endAddress.label} `} onClick={
                            () => {
                                setSelectType("END")
                                setShow(true) 
                            }}/>
                                </FloatingLabel>
                            : 
                                <FloatingLabel label = "End">
                                  <Form.Control  readOnly value = { ``} onClick={
                                        () => {
                                            setSelectType("END")
                                            setShow(true) 
                                        }}/>
                                </FloatingLabel>
                        }
            
                </Col>*/}

            </Row>

        </Container>
    )
}