import {
  SET_TIME_WINDOWS, CLEAR_TIME_WINDOWS
} from "../../actions/map";


const _null = null; 
             
export default (state = _null, { type, list}) => {
  Object.freeze(state);
  switch (type) {
    case SET_TIME_WINDOWS:
      return list;
    case CLEAR_TIME_WINDOWS:
      return _null;
    case '':
      return _null
    default:
      return state;
  }
};