import {
  SET_WAYPOINTS
} from "../../actions/map";


const _nullList = []; 
             
export default (state = _nullList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case SET_WAYPOINTS:
      return list;
    case '':
      return _nullList
    default:
      return state;
  }
};