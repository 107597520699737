import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import FloatingLabel  from 'react-bootstrap/FloatingLabel'
import InputGroup from 'react-bootstrap/InputGroup'
import Badge from 'react-bootstrap/Badge'
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	reducePressdrop, 
	reduceOpenedAt, 
	reduceDidNotOpen, 
	reduceRpTypeII
} from "../../../actions/rp"
import {
	reduceCheck1,
	reduceCheck2Tight,
	reduceCheck2Leaked,
} from "../../../actions/dc"
import {
	reduceTarget
} from "../../../actions/NumberPad"


export default function ReliefValve() {
	const dispatch = useDispatch() 
	const opened_at = useSelector((state) => state.rp_opened_at)
	const pressdrop = useSelector((state) => state.pressdrop)
	const typeII = useSelector( (state) => state.rp_typeII)
	const did_not_open = useSelector((state) =>state.rp_did_not_open)
	const check2_tight = useSelector( (state) => state.check2_tight)
	const check2_leaked = useSelector((state) => state.check2_leaked)


	useEffect(() => {
		if (pressdrop != null) {
			dispatch(reduceCheck1(pressdrop))
		}
	}, [pressdrop])

    return (
		<Container>
			<Row>
				<Col>
					<strong> Relief Valve </strong>
				</Col>
				<Col>
					<Form.Check  
						label = "Type II"
						checked = {typeII}
						onChange = {
							(event) =>{
								dispatch( reduceRpTypeII (event.target.checked) ) 

							}

						}
					/>
				</Col>
			</Row>
            <Row>
                <Col>
					<InputGroup>
						<InputGroup.Text>
							Pressdrop 
						</InputGroup.Text>
						<Form.Control
							value = {pressdrop}
							readOnly
							onFocus={
								(event) => {
									dispatch(reduceTarget(
										{
											name: "PRESSDROP",
											slice: reducePressdrop,
											target: event.target
										}
									))
								}
							}
						/>
					</InputGroup>
				</Col>
			</Row>
			<Row>
				<Col>		
					<InputGroup>
						<InputGroup.Text>
							Opened At
						</InputGroup.Text>
						<Form.Control
							value = {opened_at}
							readOnly
							onFocus={
								(event) => {
									dispatch(reduceTarget(
										{
											name: "RP_OPENED",
											slice: reduceOpenedAt,
											target: event.target
										}
									))
								}
							}

						/>
					</InputGroup>
				</Col>
					
			</Row>
			<Row>
				<Col>
					<Form.Check 
						label="Did not open"
						checked = { did_not_open}
						onChange = {
							(event)=>{
								dispatch( reduceDidNotOpen(event.target.checked) )
							}
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Label> Check #2 </Form.Label>
				</Col>
				<Col>
					<Form.Check 
						type="checkbox" 
						label="Tight" 
						checked = { check2_tight}
						onChange = {
							(event) =>{	
								dispatch(reduceCheck2Tight(event.target.checked))
								dispatch(reduceCheck2Leaked(!event.target.checked))
							}
						}
					/>
				</Col>
			</Row>
        </Container> 
    )
}