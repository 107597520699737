import * as server from "../util/settingsServer.js"

export const SET_TESTER_ADDRESS_LIST = "SET_TESTER_ADDRESS_LIST"
export const CLEAR_TESTER_ADDRESS_LIST = "CLEAR_TESTER_ADDRESS_LIST"
export const SET_DEFAULT_ADDRESS = "SET_DEFAULT_ADDRESS"
export const CLEAR_DEFAULT_ADDRESS = "CLEAR_DEFAULT_ADDRESS"


const setDefaultAddress = (obj) => ({
	type: SET_DEFAULT_ADDRESS, 
	obj
})

const clearDefaultAddress = () => ({
	type: CLEAR_DEFAULT_ADDRESS
}) 

const setList = (list) => ({
	type : SET_TESTER_ADDRESS_LIST, 
	list
})
const clearList = () =>({
	type: CLEAR_TESTER_ADDRESS_LIST
})

export const addTesterAddress = (obj) => {
	return new Promise(async(resolve, reject) =>{
		const response = await server.addTesterAddress(obj); 
		const data = await response.json()
		if(response.ok){
			resolve() 
		}
	})
}

export const removeTesterAddress = (id) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.removeTesterAddress(id); 
		const data = await response.json()
		if(response.ok){
			resolve() 
		}
	})

}

export const getTesterAddressList = () => dispatch =>  {
	return new Promise( async(resolve, reject) =>{
		const response = await server.getTesterAddressList(); 
		const data = await response.json()
		if(response.ok){
			dispatch( setList(JSON.parse(data).list) ) 
			resolve() 
		}
	})
}

export const setDftAddress = (addressID) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.setDefaultAddress(addressID); 
		const data = await response.json()
		if(response.ok){
			resolve() 
		
		}
	})

}

export const requestDefaultAddress = () => dispatch =>  {
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestDefaultAddress(); 
		const data = await response.json()
		if(response.ok){
			dispatch( setDefaultAddress(JSON.parse(data).address) ) 
			resolve( JSON.parse(data).address ) 
		}
	})
}