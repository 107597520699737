import {
    SET_TEST_TYPE, CLEAR_TEST_TYPE
} from "../../actions/assemblyApp";

const _null = null;

export default (state = _null, { type, testtype }) => {
    Object.freeze(state);
    switch (type) {
        case SET_TEST_TYPE:
            return testtype;
        case CLEAR_TEST_TYPE:
            return _null
        default:
            return state;
    }
};