import {
    SET_SYSTEM_PSI, CLEAR_SYSTEM_PSI
} from "../../actions/systemApp";

const _null = null;

export default (state = _null, { type, psi }) => {
    Object.freeze(state);
    switch (type) {
        case SET_SYSTEM_PSI:
            return psi;
        case CLEAR_SYSTEM_PSI:
            return _null
        default:
            return state;
    }
};