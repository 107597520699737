import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {voidInvoice, updateCheckNumber,
 	getCheckNumber, getInvoiceStatus, unvoidInvoice} from "../../actions/invoiceApp"

export default function InvoiceOptions(){
	const [checkNum, setCheckNum] = useState(null)
	const [voiding, setVoiding] = useState(false)
	const invoiceID = useSelector((state) => state.invoiceID)
	const [status, setStatus] = useState(null)
	const [buttonAllow, setButtonAllow] = useState(false)
	const [voided, setVoided] = useState(false)

	useEffect(()=>{
		if(checkNum){
			setButtonAllow(true)
		}else{
			setButtonAllow(false)
		}
	}, [checkNum])

	useEffect(()=>{
		if(status == "VOIDED"){
			setVoided(true)
		}else{
			setVoided(false)
		}

	}, [status])


	useEffect(()=>{
		//requesting existing check number
		if(invoiceID != null ){
			getCheckNumber( invoiceID).then( (data, err) =>{
				setCheckNum(data)
			})
			getInvoiceStatus(invoiceID).then((data, err) =>{
				setStatus(data)
			})
		}
		
	}, [invoiceID])



	const requestCheckNumber = (event) =>{	
		event.preventDefault()
		updateCheckNumber(invoiceID).then((data, err) =>{
			setCheckNum( data )
		})
	} 

	const unvoidme = (event) => {
		event.preventDefault()
		console.log("voiding" )
		unvoidInvoice(invoiceID).then((data, err) =>{	
			getInvoiceStatus(invoiceID).then((data, err) =>{
				setStatus(data)
			})
		})
	}

	const voidme = (event) =>{	
		event.preventDefault()
		console.log("voiding" )
		voidInvoice( invoiceID).then((data, err) =>{	
			getInvoiceStatus(invoiceID).then((data, err) =>{
				setStatus(data)
			})
			setVoiding(false)
		})
	}

	return(
		<Container>
			<Modal show = {voiding} centered >
				<Modal.Header closeButton onHide = {() =>setVoiding(false)}>
					Void Invoice
				</Modal.Header>
				<Modal.Body>
					<p> Are you sure you want to void this invoice ? </p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						onClick = { voidme }
					> Yes </Button>
				</Modal.Footer>
			</Modal>
			<Row>
				<Col>
					<p>
						 Status:   {status}
					</p>
				</Col>
			</Row>
			<Row>
				
				<Col xs = {8}>
					<InputGroup size ="sm">
						
						<Form.Control 
							value = { checkNum} 
							ReadOnly
						/>
						<Button 
							size = "sm" 
							onClick = { requestCheckNumber }
							disabled = { buttonAllow }
						>
								Get Check #
						</Button>
					</InputGroup>
				</Col>
				<Col> 
					{
						voided ? 
							<Button 
								size = "sm"
								onClick = { unvoidme }
							> 
								Unvoid
							</Button>
						: 
							<Button 
								size= "sm"
								variant = "danger"
								onClick = { () => setVoiding(true)}
								> 

									Void
							 </Button>

					}
				</Col>
			</Row>
		</Container>
	)
}