import {
    SET_FINAL_RP_DID_NOT_OPEN, CLEAR_FINAL_RP_DID_NOT_OPEN
} from "../../actions/rp";

const _null = null;

export default (state = _null, { type, truefalse }) => {
    Object.freeze(state);
    switch (type) {
        case SET_FINAL_RP_DID_NOT_OPEN:
            return truefalse;
        case CLEAR_FINAL_RP_DID_NOT_OPEN:
            return _null
        default:
            return state;
    }
};