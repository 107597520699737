import {
    SET_TARGET, 
    CLEAR_TARGET
} from "../../actions/NumberPad.js";

const _nullTarget = null

export default (state = _nullTarget, { type, target }) => {
    Object.freeze(state);
    switch (type) {
        case SET_TARGET:
            return target;
        case CLEAR_TARGET:
            return _nullTarget;
        case "":
            return _nullTarget;
        default:
            return state;
    }
};