import AssemblyList from "./AssemblyList"
import Invoice from "../invoice/Invoice"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    clearInvoiceID, clearBillingID, clearItemID, clearItemList, clearOptionsList
} from "../../actions/invoiceApp"

export default function StopBody() {
    const [key, setKey] = useState("services")
    const dispatch = useDispatch()
    const stopID = useSelector( (state) => state.stopID)
    useEffect(()=>{
        if(stopID == null){
            let plist = []
            let p1 = dispatch(clearInvoiceID() )
            let p2 = dispatch(clearBillingID() )
            let p3 = dispatch(clearItemID() )
            let p4 = dispatch(clearItemList() )
            let p5 =dispatch( clearOptionsList() )
            plist.push(p1)        
            plist.push(p2)   
            plist.push(p3)   
            plist.push(p4)   
            plist.push(p5)
            Promise.all(plist).then((data, err) =>{
             

            })   

        }

    }, [stopID])

    useEffect(()=>{
        if(key != "invoice"){
            let plist = []
            let p1 = dispatch(clearInvoiceID() )
            let p2 = dispatch(clearBillingID() )
            let p3 = dispatch(clearItemID() )
            let p4 = dispatch(clearItemList() )
            let p5 =dispatch( clearOptionsList() )
            plist.push(p1)        
            plist.push(p2)   
            plist.push(p3)   
            plist.push(p4)   
            plist.push(p5)
            Promise.all(plist).then((data, err) =>{
            })   
        }
    }, [key])

    return (
        <Tabs
            activeKey = {key}
            id="uncontrolled-tab-example"
            className="mb-3"
              onSelect={(k) => setKey(k)}
        >
            <Tab eventKey="services" title="Assemblies" mountOnEnter unmountOnExit>
                <AssemblyList />
            </Tab>
            <Tab eventKey="invoice" title="Invoice" mountOnEnter unmountOnExit>
                <Invoice />
            </Tab>
        </Tabs>
   )
}