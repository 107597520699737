import {
    SET_ASSEMBLY_MODEL, CLEAR_ASSEMBLY_MODEL
} from "../../actions/assemblyApp";

const _null = null;

export default (state = _null, { type, model }) => {
    Object.freeze(state);
    switch (type) {
        case SET_ASSEMBLY_MODEL:
            return model;
        case CLEAR_ASSEMBLY_MODEL:
            return _null
        default:
            return state;
    }
};