import {AdvancedMarker, APIProvider,
 Map, useMap, useMapsLibrary, Pin} from '@vis.gl/react-google-maps';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Accordion from 'react-bootstrap/Accordion'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reduceWaypoints, reduceRoute, reduceLegs, reduceDistanceMatrix } from "../../actions/map"
import { requestStoplist, requestAddressDetails, requestStopsRoute } from "../../actions/stopsApp"
import StartEnd from "./start_end"
import {
    getTesterAddressList
} from "../../actions/settings"
import {addSeconds, format, compareAsc} from "date-fns"

function distanceMatrixToAdjacencyList(distanceMatrix) {
    const adjacencyList = {};

    // Iterate over the matrix to create the adjacency list
    for (let i = 0; i < distanceMatrix.length; i++) {
        const node = String.fromCharCode(65 + i);  // Convert index to node label ('A', 'B', 'C', ...)
        adjacencyList[node] = {};

        for (let j = 0; j < distanceMatrix[i].length; j++) {
            if (i !== j && distanceMatrix[i][j] > 0) {  // Ignore self-loops (distance[i][i]) and zero distances
                const neighbor = String.fromCharCode(65 + j);  // Convert index to node label
                adjacencyList[node][neighbor] = distanceMatrix[i][j];
            }
        }
    }

    return adjacencyList;
}

export default function DistanceMatrix(){
	const routerLib = useMapsLibrary('routes');
	const stoplist  = useSelector( (state) => state.stoplist) 
	const [stopOrder, setStopOrder] = useState([])
	const date = useSelector( (state) => state.dispatchDate) 
    const waypoints = useSelector((state) => state.waypoints )
    const start = useSelector((state) => state.startpoint)
    const dft= useSelector( (state) => state.default_address)
    const end = useSelector((state) => state.endpoint)
    const dispatch = useDispatch()
    const [origins, setOrigins] = useState([])


    useEffect(()=>{
        if(dft != null){   
            let temparr = []
            let promises = [] 
            temparr.push(`${dft.street}, ${dft.city}, ${dft.state} ${dft.zipcode}`) 
            for( let i = 0; i < stoplist.length; i++){
                //request address details 
                promises.push ( requestAddressDetails( stoplist[i].addressID ).then( (data, err) => {
                    let str = `${data.street}, ${data.city}, ${data.state} ${data.zipcode}`
                    temparr.push( str )
                })) 
            }
            Promise.all( promises) .then((data, err) =>{
                 setOrigins(temparr)   
            })
           
        }
    }, [dft, stoplist])

	const router = useMemo(
      () => routerLib && new routerLib.DistanceMatrixService(),
      [routerLib]
    );
	const distanceMatrix = () =>{
		return new Promise (async( resolve, reject) =>{
            //console.log(origins)
            let rr = await router.getDistanceMatrix(
              {
                destinations:  origins,
                origins :  origins, 
                travelMode : "DRIVING", 
              }
            )
            setStopOrder([])
            console.log("response from distance matrix: ", rr)
            //convert to graph
            let graph = []
            let headers = []
            for (let i = 0; i < rr.originAddresses.length; i++) { 
                headers.push()
            }
            for (let i = 0; i < rr.rows.length; i++) {
                let temparr = []
                for (let j = 0; j < rr.rows[i].elements.length; j++) {
                    temparr.push(rr.rows[i].elements[j].duration.value)
                }
                graph.push(temparr)
            }
            dispatch(reduceDistanceMatrix(graph) )
            resolve()
        })
	}

    useEffect(() => {
      if (!router) return;
    }, [router]);

    useEffect( ()=> {
        if(origins.length > 0 ){    
            //console.log( "Getting a distance matrix: ", origins)
           // distanceMatrix() 
        }
    }, [origins])



	return(
        <>
          <Button onClick = {distanceMatrix }>Route</Button>
		</>
	)
}