import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    reduceSO1, reduceSO2, reduceUpstream,
    reduceSO1Rusted, reduceSO2Rusted,
    reduceUpstreamRusted, reduceComment
} from "../../../actions/remarksApp"


function Display() {
    const dispatch = useDispatch()
    const so1 = useSelector((state) => state.so1)
    const so2 = useSelector((state) => state.so2)
    const upstream = useSelector((state) => state.upstream)
    const so1_rusted = useSelector((state) => state.so1_rusted)
    const so2_rusted = useSelector((state) => state.so2_rusted)
    const upstream_rusted = useSelector((state) => state.upstream_rusted)
    const comment = useSelector((state) => state.comment)
    const [so1_off, setSO1Off] = useState(false)
    const [so2_off, setSO2Off] = useState(false)
    const [upstream_off, setUpstreamOff] = useState(false)

    useEffect(() => {
        if (so1 === null) {
            setSO1Off(false)
        } else {
            setSO1Off(!so1)
        }
    }, [so1])

    useEffect(() => {
        if (so2 === null) {
            setSO2Off(false)
        } else {
            setSO2Off(!so2)
        }
    }, [so2])
    useEffect(() => {
        if (upstream === null) {
            setUpstreamOff(false)
        } else {
            setUpstreamOff(!upstream)
        }
    }, [upstream])

    
    return (
        <>

            <Row xs={4} sm={4}>
                <Col xs={3} sm={3}>
                    <Form.Label> SO1 </Form.Label>
                </Col>
                <Col>
                    <Form.Check
                        type="radio"
                        label="ON"
                        checked={so1}
                        onChange={
                            (event) =>
                            {
                                dispatch(reduceSO1(event.target.checked))
                            }
                        }
                    />
                </Col>
                <Col>
                    <Form.Check
                        type="radio"
                        label="OFF"
                        checked={so1_off}
                        onChange={
                            (event) => {
                                dispatch( reduceSO1( !event.target.checked))
                            }
                        }
                    />
                </Col>
                <Col>
                    <Form.Check
                        type="checkbox"
                        label="Rusted"
                        checked={so1_rusted}
                        onChange={
                            (event) => {
                                dispatch(reduceSO1Rusted(event.target.checked))
                            }
                        }
                    />
                </Col>
            </Row>
            <Row xs={4} sm={4}>
                <Col xs={3} sm={3}>
                    <Form.Label> SO2 </Form.Label>
                </Col>
                <Col>
                    <Form.Check
                        type="radio"
                        label="ON"
                        checked={so2}
                        onChange={
                            (event) => {
                                dispatch(reduceSO2(event.target.checked))
                            }
                        }
                    />
                </Col>
                <Col>
                    <Form.Check
                        type="radio"
                        label="OFF"
                        checked={so2_off}
                        onChange={
                            (event) => {
                                dispatch(reduceSO2(!event.target.checked))
                            }
                        } />
                </Col>
                <Col>
                    <Form.Check
                        type="checkbox"
                        label="Rusted"
                        checked={so2_rusted}
                        onChange={
                            (event) => {
                                dispatch(reduceSO2Rusted(event.target.checked))
                            }
                        }
                    />
                </Col>
            </Row>
            <Row xs={4} sm={4}>
                <Col xs={3} sm={3}>
                    <Form.Label><small> Upstream</small> </Form.Label>
                </Col>
                <Col>
                    <Form.Check
                        type="radio"
                        label="ON"
                        checked={upstream}
                        onChange={
                            (event) => {
                                dispatch(reduceUpstream(event.target.checked))
                            }
                        }
                    />
                </Col>
                <Col>
                    <Form.Check
                        type="radio"
                        label="OFF"
                        checked={upstream_off}
                        onChange={
                            (event) => {
                                dispatch(reduceUpstream(!event.target.checked))
                            }
                        }
                    />
                </Col>
                <Col>
                    <Form.Check
                        type="checkbox"
                        label="Rusted"
                        checked={upstream_rusted}
                        onChange={
                            (event) => {
                                dispatch(reduceUpstreamRusted(event.target.checked))
                            }
                        }
                    />
                </Col>
            </Row>
             <Row>
                <Col>
                    <FloatingLabel label="Comment">
                        <Form.Control
                            value={comment}
                            onChange={
                                (event) => {
                                    dispatch( reduceComment(event.target.value) )
                                }
                            }
                            as =  "textarea"
                            style={{ height: '130px', width: '300px'}}
                        />
                    </FloatingLabel>

                </Col>
            </Row>
        </>
    )
}


function Editable(){
    const [so1, setSo1] = useState(true)
    const [so2, setSo2] = useState(true)
    const [upstream, setUpstream] = useState(true)
    const [restored, setRestored] = useState(true)
    const [comment, setComment] = useState(null)
    return (
        <>
            <Row>
                <Col>
                    <FloatingLabel label="Comment">
                        <Form.Control />
                    </FloatingLabel>

                </Col>
            </Row>
            <Row xs={4} sm={4}>
                <Col xs={3} sm={3}>
                    <Form.Label> SO1 </Form.Label>
                </Col>
                <Col>
                    <Form.Check type="radio" label="ON" />
                </Col>
                <Col>
                    <Form.Check type="radio" label="OFF" />
                </Col>
                <Col>
                    <Form.Check type="checkbox" label="Rusted" />
                </Col>
            </Row>
            <Row xs={4} sm={4}>
                <Col xs={3} sm={3}>
                    <Form.Label> SO2 </Form.Label>
                </Col>
                <Col>
                    <Form.Check type="radio" label="ON" />
                </Col>
                <Col>
                    <Form.Check type="radio" label="OFF" />
                </Col>
                <Col>
                    <Form.Check type="checkbox" label="Rusted" />
                </Col>
            </Row>
            <Row xs={4} sm={4}>
                <Col xs={3} sm={3}>
                    <Form.Label><small> Upstream</small> </Form.Label>
                </Col>
                <Col>
                    <Form.Check type="radio" label="ON" />
                </Col>
                <Col>
                    <Form.Check type="radio" label="OFF" />
                </Col>
                <Col>
                    <Form.Check type="checkbox" label="Rusted" />
                </Col>
            </Row>
        </>
    )
}

export default function Remarks() {
    const edit = true
    return (
        <Container>
            <Row>
                <Col>
                    <strong> Remarks </strong>
                </Col>
            </Row>
          
            <Display />

            
           
        </Container>
   )
}