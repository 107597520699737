

export const SET_INSTALLATION = "SET_INSTALLATION"
export const SET_ASSEMBLY = "SET_ASSEMBLY"
export const SET_ORIENTATION = "SET_ORIENTATION"
export const SET_AIRGAP = "SET_AIRGAP"
export const SET_USC = "SET_USC"

export const CLEAR_INSTALLATION = "CLEAR_INSTLLATION"
export const CLEAR_ASSEMBLY = "CLEAR_ASSEMBLY"
export const CLEAR_ORIENTATION = "CLEAR_ORIENTATION"
export const CLEAR_AIRGAP = "CLEAR_AIRGAP"
export const CLEAR_USC = "CLEAR_USC"


/* CLEAN UP*/
const clearInstallation = () => ({ type: CLEAR_INSTALLATION })
const clearAssembly = () => ({ type: CLEAR_ASSEMBLY })
const clearOrientation = () => ({ type: CLEAR_ORIENTATION })
const clearAirgap = () => ({ type: CLEAR_AIRGAP })
const clearUSC = () => ({ type: CLEAR_USC })

export const clearApproved = () => dispatch => {
    return new Promise(async (resolve, reject) => {
        let p1 = dispatch(clearInstallation())
        let p2 = dispatch(clearAssembly())
        let p3 = dispatch(clearOrientation())
        let p4 = dispatch(clearAirgap())
        let p5 = dispatch(clearUSC())
        Promise.all([p1, p2, p3, p4, p5]).then((data, err) => {
            resolve()
        })
    })
}


/*SETTERS*/
const setInstallation = (truefalse) => ({ type: SET_INSTALLATION, truefalse })
const setAssembly = ( truefalse) => ({ type: SET_ASSEMBLY , truefalse})
const setOrientation = (truefalse) => ({ type: SET_ORIENTATION, truefalse})
const setAirgap = (truefalse) => ({ type: SET_AIRGAP, truefalse})
const setUSC = (truefalse) => ({ type: SET_USC, truefalse })

export const reduceInstallation = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setInstallation(truefalse))
        resolve()
    })
}
export const reduceAssembly = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setAssembly(truefalse))
        resolve()
    })
}
export const reduceOrientation = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setOrientation(truefalse))
        resolve()
    })
}
export const reduceAirgap = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setAirgap(truefalse))
        resolve()
    })
}
export const reduceUSC = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setUSC(truefalse))
        resolve()
    })
}

