import {
  SET_DISTANCE_MATRIX, CLEAR_DISTANCE_MATRIX
} from "../../actions/map";


const _null = null; 
             
export default (state = _null, { type, graph}) => {
  Object.freeze(state);
  switch (type) {
    case SET_DISTANCE_MATRIX:
      return graph;
    case CLEAR_DISTANCE_MATRIX: 
      return _null
    case '':
      return _null
    default:
      return state;
  }
};