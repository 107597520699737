import {
    SET_STOP_ID, CLEAR_STOP_ID
} from "../../actions/stopsApp";


const _nullID = null;

export default (state = _nullID, { type, id }) => {
    Object.freeze(state);
    switch (type) {
        case SET_STOP_ID:
            return id;
        case CLEAR_STOP_ID:
            return _nullID
        default:
            return state;
    }
};