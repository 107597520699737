import {
    SET_LINES, CLEAR_LINES
} from "../../actions/removed";

const _null = null;

export default (state = _null, { type, truefalse }) => {
    Object.freeze(state);
    switch (type) {
        case SET_LINES:
            return truefalse;
        case CLEAR_LINES:
            return _null
        default:
            return state;
    }
};