import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useState, useEffect, useRef, useCallback } from "react";
import Badge from 'react-bootstrap/Badge';
import { useSelector, useDispatch } from "react-redux";
import {
	reduceDisc, reduceSpring, reduceGuide, reduceDiaph, reduceSeat,
	reduceOring, reduceModule, reduceRubberKit, reduceAiDisc,
	reduceAiSpring, reduceCheckSpring, reduceCheckDisc,
	reduceFloat
} from "../../../actions/parts"

export default function Repairs() {
	const disc = useSelector((state) => state.disc);
	const spring = useSelector((state) => state.spring)
	const guide = useSelector((state) => state.guide)
	const diaph = useSelector((state) => state.diaphragm)
	const seat = useSelector((state) => state.seat)
	const o_ring = useSelector((state) => state.oring)
	const modules = useSelector((state) => state.module)
	const rubber_kit = useSelector((state) => state.rubber_kit)
	const ai_disc = useSelector((state) => state.ai_disc)
	const ai_spring = useSelector((state) => state.ai_spring)
	const check_disc = useSelector((state) => state.check_disc)
	const check_spring = useSelector((state) => state.check_spring)
	const float = useSelector((state) => state.ff)
	const dispatch = useDispatch()

	return (

		<Container>
			<Row>
				<Col>
					<strong> Parts </strong>
				</Col>
			</Row>
			<Row xs={2} sm={2}>

				<Col>
					<Form.Check id="disc"
						label="Disc"
						checked={disc}
						onChange={
							(event) => {
								dispatch( reduceDisc( event.target.checked))
							}
						}
					/>
				</Col>
				<Col>
					<Form.Check
						id="spring"
						label="Spring"
						checked={spring}
						onChange={
							(event) => {
								dispatch(reduceSpring(event.target.checked))
							}
						}
					/>
				</Col>
				<Col>
					<Form.Check
						id="guide"
						label="Guide"
						checked={guide}
						onChange={
							(event) => {
								dispatch(reduceGuide(event.target.checked))
							}
						}
					/>
				</Col>
				<Col>
					<Form.Check
						id="diagh"
						label="Diaphragm"
						checked={diaph}
						onChange={
							(event) => {
								dispatch(reduceDiaph(event.target.checked))
							}
						}
						/>
				</Col>
				<Col>
					<Form.Check
						id="seat"
						label="Seat"
						checked={seat}
						onChange={
							(event) => {
								dispatch(reduceSeat(event.target.checked))
							}
						}
					/>
				</Col>
				<Col>
					<Form.Check
						id="o_ring"
						label="O-Ring(s)"
						checked={o_ring}
						onChange={
							(event) => {
								dispatch(reduceOring(event.target.checked))
							}
						}
					/>
				</Col>
				<Col>
					<Form.Check
						id="module"
						label="Module"
						checked={modules}
						onChange={
							(event) => {
								dispatch(reduceModule(event.target.checked))
							}
						}
					/>
				</Col>
				<Col>
					<Form.Check
						id="rubber_kit"
						label="Rubber Kit"
						checked={rubber_kit}
						onChange={
							(event) => {
								dispatch(reduceRubberKit(event.target.checked))
							}
						}
					/>
				</Col>
				<Col>
					<Form.Check
						id="check_disc"
						label="Check Disc"
						checked={check_disc}
						onChange={
							(event) => {
								dispatch(reduceCheckDisc(event.target.checked))
							}
						}
					/>
				</Col>
				<Col>
					<Form.Check
						id="check_spring"
						label="Check Spring"
						checked={check_spring}
						onChange={
							(event) => {
								dispatch(reduceCheckSpring(event.target.checked))
							}
						}
					/>
				</Col>
				<Col>
					<Form.Check
						id="float"
						label="Float"
						checked={float}
						onChange={
							(event) => {
								dispatch(reduceFloat(event.target.checked))
							}
						}
					/>
				</Col>
				<Col>
					<Form.Check
						id="air_spring"
						label="A/I Spring"
						checked={ai_spring}
						onChange={
							(event) => {
								dispatch(reduceAiSpring(event.target.checked))
							}
						}
					/>
				</Col>
				<Col>
					<Form.Check
						id="air_disc"
						label="A/I Disc"
						checked={ai_disc}
						onChange={
							(event) => {
								dispatch(reduceAiDisc(event.target.checked))
							}
						}
					/>
				</Col>
			</Row>
		</Container>
	)

}