import {
    SET_CHECK2_LEAKED, CLEAR_CHECK2_LEAKED
} from "../../actions/dc";

const _null = null;

export default (state = _null, { type, truefalse }) => {
    Object.freeze(state);
    switch (type) {
        case SET_CHECK2_LEAKED:
            return truefalse;
        case CLEAR_CHECK2_LEAKED:
            return _null
        default:
            return state;
    }
};