import {
    SET_DMR, CLEAR_DMR
} from "../../actions/systemApp";

const _null = null;

export default (state = _null, { type, dmr }) => {
    Object.freeze(state);
    switch (type) {
        case SET_DMR:
            return dmr;
        case CLEAR_DMR:
            return _null
        default:
            return state;
    }
};