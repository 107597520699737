import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

export default function NumberPad() {
	const [string, setString] = useState('');
	const dispatch = useDispatch()
	const target = useSelector((state) => state.target)

	useEffect(() => {
		if (target != null) {
			if (target.name !== "DMR") {
				if (string !== '') {
					target.target.value = string.replace(/^0+/, "0")
					dispatch(target.slice(string.replace(/^0+/, "0")))
				} else {
					target.target.value = string.replace(/^0+/, "0")
					dispatch(target.slice(string.replace(/^0+/, "0") ) )
				}
		
			} else {
				target.target.value = string;
				dispatch(target.slice(string))
			}
		}
	}, [string])

	useEffect(() => {
		console.log("Target: ", target)
		if (target != null) {
			if(target.current == null){
				setString('')
			}else{
				setString(String( target.current) )
			}
			
		}
	}, [target])

	const grabValue = (event) => {
		if (target != null) {
			switch (event.target.id) {
				case 'CL':
					setString('')
					break;
				case '.':
					if (target.name !== "DMR") {
						if (!string.includes('.')) {
							if(string.length === 0){
								setString(`0.`)
							}else{
								setString(`${string}${event.target.id}`)
							}
								
						}
					}
					break;
				default:
					setString(`${string}${event.target.id}`)
			}
		}
	}
	
    return (
		<Container fluid >
			<br/>
			<Row xs={1} sm={1} md={1} lg={ 1}>
				
				<ButtonGroup>
					<Button onClick={grabValue} bsPrefix="button-pad" id="1" >1 </Button>
				
						<Button onClick={grabValue} bsPrefix="button-pad" id="2" >2</Button>
			
						<Button onClick={grabValue} bsPrefix="button-pad" id="3" >3 </Button>
				</ButtonGroup>
				<ButtonGroup>
						<Button onClick={grabValue} bsPrefix="button-pad" id="4" >4 </Button>
				
						<Button onClick={grabValue} bsPrefix="button-pad" id="5" >5</Button>
				
					<Button onClick={grabValue} bsPrefix="button-pad" id="6" >6 </Button>
				</ButtonGroup>
				<ButtonGroup>
						<Button onClick={grabValue} bsPrefix="button-pad" id="7" >7 </Button>
				
						<Button onClick={grabValue} bsPrefix="button-pad" id="8" >8</Button>
				
						<Button onClick={grabValue} bsPrefix="button-pad" id="9" >9 </Button>
				</ButtonGroup>
				<ButtonGroup>
						<Button onClick={grabValue} bsPrefix="button-pad" id="0" >0 </Button>
				
						<Button onClick={grabValue} bsPrefix="button-pad" id="." >.</Button>
				
						<Button onClick={grabValue} bsPrefix="button-pad" id="CL" >CL</Button>
				</ButtonGroup>
			</Row>
		</Container>
    )
}