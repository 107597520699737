

export const SET_ASSEMBLY_ID = "SET_ASSEMBLY_ID"
export const CLEAR_ASSEMBLY_ID = "CLEAR_ASSEMBLY_ID"
export const SET_ASSEMBLY_SERIAL = "SET_ASSEMBLY_SERIAL"
export const CLEAR_ASSEMBLY_SERIAL = "CLEAR_ASSEMBLY_SERIAL"
export const SET_ASSEMBLY_LOCATION = "SET_ASSEMBLY_LOCATION"
export const CLEAR_ASSEMBLY_LOCATION = "CLEAR_ASSEMBLY_LOCATION"
export const SET_ASSEMBLY_MAKE = "SET_ASSEMBLY_MAKE"
export const CLEAR_ASSEMBLY_MAKE = "CLEAR_ASSEMBLY_MAKE"
export const SET_ASSEMBLY_MODEL = "SET_ASSEMBLY_MODEL"
export const CLEAR_ASSEMBLY_MODEL = "CLEAR_ASSEMBLY_MODEL"
export const SET_ASSEMBLY_SIZE = "SET_ASSEMBLY_SIZE"
export const CLEAR_ASSEMBLY_SIZE = "CLEAR_ASSEMBLY_SIZE"
export const SET_ASSEMBLY_HAZARD = "SET_ASSEMBLY_HAZARD"
export const CLEAR_ASSEMBLY_HAZARD = "CLEAR_ASSEMBLY_HAZARD"
export const SET_ASSEMBLY_TYPE = "SET_ASSEMBLY_TYPE"
export const CLEAR_ASSEMBLY_TYPE = "CLEAR_ASSEMBLY_TYPE"
export const SET_TEST_TYPE = "SET_TEST_TYPE"
export const CLEAR_TEST_TYPE = "CLEAR_TEST_TYPE"

const setAssemblyID = (id) => ({
    type: SET_ASSEMBLY_ID,
    id
})
const clearAssemblyID = () => ({
    type: CLEAR_ASSEMBLY_ID
})

const setSerialNumber = (serial) => ({
    type: SET_ASSEMBLY_SERIAL, 
    serial
})
const clearSerialNumber = () => ({
    type: CLEAR_ASSEMBLY_SERIAL
})

const setAssemblyLocation = (location) => ({
    type: SET_ASSEMBLY_LOCATION, 
    location
})

const clearAssemblyLocation = () => ({
    type: CLEAR_ASSEMBLY_LOCATION
})
const setAssemblyMake = (make) => ({
    type: SET_ASSEMBLY_MAKE, 
    make
})
const clearAssemblyMake = () => ({
    type: CLEAR_ASSEMBLY_MAKE
})

const setAssemblySize = (size) => ({
    type: SET_ASSEMBLY_SIZE,
    size
})
const clearAssemblySize = () => ({
    type: CLEAR_ASSEMBLY_SIZE
})

const setAssemblyModel = (model) => ({
    type: SET_ASSEMBLY_MODEL, 
    model
})
const clearAssemblyModel = () => ({
    type: CLEAR_ASSEMBLY_MODEL
})
const setAssemblyHazard = (hazard) => ({
    type: SET_ASSEMBLY_HAZARD, 
    hazard
})
const clearAssemblyHazard = () => ({
    type: CLEAR_ASSEMBLY_HAZARD
})
const setAssemblyType = (devicetype) => ({
    type: SET_ASSEMBLY_TYPE,
    devicetype
})
const clearAssemblyType = () => ({
    type: CLEAR_ASSEMBLY_TYPE
})

const setTestType = (testtype) => ({
    type: SET_TEST_TYPE,
    testtype
})

const clearTestType = () => ({
    type: CLEAR_TEST_TYPE
})

export const clearAssembly = () => dispatch => {
    return new Promise((resolve, reject) => {
        let p1 = dispatch(clearTestType())
        let p2 = dispatch(clearAssemblyHazard())
        let p3 = dispatch(clearAssemblyLocation())
        let p4 = dispatch(clearSerialNumber());
        let p5 = dispatch(clearAssemblyMake());
        let p6 = dispatch(clearAssemblyModel());
        let p7 = dispatch(clearAssemblyType());
        let p8 = dispatch( clearAssemblySize() )
        Promise.all([p1, p2, p3, p4, p5, p6, p7, p8]).then((data, err) => {
            resolve()
        })
    })
}

export const reduceSerial = (serial) => dispatch => {
    return new Promise(async (resolve, reject) => {
        dispatch(setSerialNumber(serial))
        resolve()
    })
}
export const reduceLocation = (location) => dispatch => {
    return new Promise(async (resolve, reject) => {
        dispatch(setAssemblyLocation(location))
        resolve()
    })
}

export const reduceMake = (make) => dispatch => {
    return new Promise(async (resolve, reject) => {
        dispatch(setAssemblyMake(make))
        resolve()
    })
}

export const reduceModel = (model) => dispatch => {
    return new Promise(async (resolve, reject) => {
        dispatch(setAssemblyModel(model))
        resolve()
    })
}

export const reduceSize = (size) => dispatch => {
    return new Promise(async (resolve, reject) => {
        dispatch(setAssemblySize(size))
        resolve()
    })
}

export const reduceHazard = (hazard) => dispatch => {
    return new Promise(async (resolve, reject) => {
        dispatch(setAssemblyHazard(hazard))
        resolve()
    })
}

export const reduceType = (devicetype) => dispatch => {
    return new Promise(async (resolve, reject) => {
        dispatch(setAssemblyType(devicetype))
        resolve()
    })
}

export const reduceTestType = (testtype) => dispatch => {
    return new Promise(async (resolve, reject) => {
        dispatch(setTestType(testtype))
        resolve()
    })
}