import {
    SET_AI_OPENED_FULLY, CLEAR_AI_OPENED_FULLY
} from "../../actions/xvba";

const _null = null;

export default (state = _null, { type, truefalse }) => {
    Object.freeze(state);
    switch (type) {
        case SET_AI_OPENED_FULLY:
            return truefalse;
        case CLEAR_AI_OPENED_FULLY:
            return _null
        default:
            return state;
    }
};