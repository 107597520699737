import {
    NOT_TESTED_TRUE, NOT_TESTED_FALSE
} from "../../actions/stopsApp";

const _nullFalse = false; 

export default (state = _nullFalse, { type }) => {
    Object.freeze(state);
    switch (type) {
        case NOT_TESTED_TRUE:
            return true;
        case NOT_TESTED_FALSE: 
            return _nullFalse
        case '':
            return _nullFalse; 
        default:
            return state;
    }
};