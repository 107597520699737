import {
    SET_FINAL_FAIL, CLEAR_FINAL_FAIL
} from "../../actions/finalResults";

const _null = null;

export default (state = _null, { type, truefalse }) => {
    Object.freeze(state);
    switch (type) {
        case SET_FINAL_FAIL:
            return truefalse;
        case CLEAR_FINAL_FAIL:
            return _null
        default:
            return state;
    }
};