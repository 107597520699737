import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import LineItem from "./LineItem"
import Spinner from 'react-bootstrap/Spinner'
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { requestItemList, createLineItem, requestOptionsList} from "../../actions/invoiceApp"


export default function LineItems(){
	const list = useSelector( (state) => state.itemlist)
	const invoiceID = useSelector((state) => state.invoiceID)
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch(); 

	useEffect(()=>{
		dispatch( requestOptionsList() ).then((data, err) => {
		})
	}, [])

	useEffect(()=>{
		if(invoiceID != null){
			setLoading(true)
			dispatch( requestItemList(invoiceID) ).then((data, err) =>{
				setLoading(false)

			})
		}
	}, [invoiceID])

	const renderlist = list.map( (item) =>{
		return(
			<LineItem item = {item} />
		)
	})
	const addItem = () =>{
		if(invoiceID != null){
			createLineItem(invoiceID).then( (data, err) =>{
				setLoading(true)
				dispatch( requestItemList(invoiceID) ).then((data, err) =>{
					setLoading(false)

				})
			})
		}
	}

	return(
		<Table responsive size = "sm" striped bordered>
			<thead> 
				<tr>
					<th><small>Item</small></th>
					<th><small>Descr</small></th>
					<th><small>Qty</small> </th>
					<th><small>Price</small></th>
					<th><small>Total</small></th>
					<th></th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{
					loading ?	
						<Spinner />
					: 
						<>
							{renderlist}
						</>

				}
				
				<tr>
					<td colSpan = {7}>
						<Button 
							size= "sm" 
							variant ="secondary"
							onClick = {addItem}

						> + </Button>
					</td>
				</tr>
			</tbody>
		</Table>
	)
}