import {
    SET_DC_TYPE_II, CLEAR_DC_TYPE_II
} from "../../actions/dc";

const _null = null;

export default (state = _null, { type, truefalse }) => {
    Object.freeze(state);
    switch (type) {
        case SET_DC_TYPE_II:
            return truefalse;
        case CLEAR_DC_TYPE_II:
            return _null
        default:
            return state;
    }
};