import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { requestItemDetails,  updateLineItem, removeLineItem, requestItemList} from "../../actions/invoiceApp"

export default function LineItem(props){
	const optionlist = useSelector((state) => state.optionslist)
	const itemID = useSelector((state) => state.itemID)
	const [mydetails, setMyDetails] = useState(null)
	const [item, setItem] = useState(null)
	const [itemIndex, setItemIndex] = useState(null)
	const [description, setDescription] = useState(null)
	const [qty, setQty] = useState(null)
	const [price, setPrice] = useState(null)
	const [total, setTotal] = useState(null)
	const [edit, setEdit] = useState(false); 
	const [loading, setLoading] = useState(false)
	const [updating, setUpdating] = useState(false)
	const dispatch = useDispatch()
	const invoiceID = useSelector((state) => state.invoiceID)

	useEffect(()=>{
		if(itemIndex != null){
			setPrice(optionlist[itemIndex].unitPrice)
		setDescription(optionlist[itemIndex].description)
		}
		

	}, [itemIndex] )
	useEffect(()=>{
		if(mydetails != null){
			setDescription(mydetails.description)
			setQty(mydetails.quantity)
			setPrice(mydetails.unitPriceDefined)
			setTotal(mydetails.amount)
		}
	}, [mydetails])

	useEffect(()=>{
		setLoading(true)
		requestItemDetails( props.item.id).then((data, err) =>{
			setMyDetails(data)
			setLoading(false)
		})			
	}, [])

	const save = ()=>{
		let obj = {}; 
		if(itemIndex != null){
			obj.item = optionlist[itemIndex].item;
		}
		 
		obj.description = description; 
		obj.quantity = qty; 
		obj.price = price; 
		obj.total = price * qty; 
		updateLineItem(props.item.id, obj).then((data, err) =>{
			setEdit(false)
			setLoading(true)
			requestItemDetails( props.item.id).then((data, err) =>{
				setLoading(false)

			})	
		})
	}
	const remove = ()=>{
		removeLineItem(props.item.id).then( (data, err) =>{
			dispatch(requestItemList(invoiceID)).then((data, err) =>{
			})
		})
	}

	const renderoptions = optionlist.map( (op, index) =>{
		return(
			<option value= {index}> {op.item}</option>
		)
	})
	return(

		<> {
			edit ?
				<Modal show = {true} centered>
					<Modal.Header>
						Edit line item
					</Modal.Header>
					<Modal.Body>
						<Form.Label> Name</Form.Label>
						<Form.Select value = {itemIndex} onChange= {(event) => setItemIndex(event.target.value)}>
							<option> select one </option>
							{renderoptions}
						</Form.Select>
						<Form.Label> Description</Form.Label>
						<Form.Control 
							value = {description}
							onChange = {(event) => setDescription(event.target.value) }
						/>
						<Form.Label> Quantity</Form.Label>
						<Form.Control 
							type="number" 
							min="1" 
							step="1.0"
							value = {qty}
							onChange = {(event) => setQty(event.target.value) }
						/>
						<Form.Label> Price</Form.Label>
						<Form.Control 
					
							value = {price}
							onChange = {(event) => setPrice(event.target.value) }
						/>
						<Form.Label>Total </Form.Label>
						${ price * qty }
						</Modal.Body>
						<Modal.Footer>
				
						{

							updating ?
								<Spinner />
							: 
								<Button
									 size= "sm"
									 variant = "secondary"
									 onClick = { save}
								> Save </Button>

						}
					</Modal.Footer>
					
				</Modal>
			: 
				<tr>
					<td>
						{item}
					</td>
					<td>
						{description}					
					</td>
					<td>
						{qty}
					</td>
					<td>
						${price}
					</td>
					<td>
						${price * qty}
					</td>
					<td> 
						<Button
							 size= "sm"
							 variant = "link"
							 onClick = {
							 	() => setEdit(true)

							 }
						> 
							edit
						 </Button>
					</td>
					<td>
						<Button 
							size = "sm"
							variant = "secondary"
							onClick = {remove} > - </Button>
					</td>
				</tr>


		}</>


			
	)
}