
import { combineReducers } from 'redux';
import session from './session/session';
/*import invoice from "./invoice/invoice"; 
import assembly from "./assembly/assembly"; 
import message from "./message/message"; 
import confinedEntry from "./confinedEntry/confinedEntry"; 
import googleRoutes from "./googleRoutes/googleRoutes"; 
import lineitems from "./lineitems/lineitems"
import numberPad from "./numberPad/numberPad"
import updatedVal from "./numberPad/updatedVal"
import testlist from "./testlist/testlist"
import assemblylist from "./assemblyList/assemblyList"
import report from "./report/report"
import invoiceItems from "./invoiceItems/invoiceItems"
import billingAddress from "./billingAddress/billingAddress"; 
import selectedDate from "./selectedDate/selectedDate"
import confinedEntryList from "./confinedEntryList/confinedEntryList"
import testerslist from "./testerlist/testerlist"
import assembly_id from "./assembly/assemblyID"
import assembly_type from "./assembly/assembly_type"
import report_id from "./testReport/report_id"
import check1_psid from "./testReport/check1_psid"
import check2_psid from "./testReport/check2_psid"
import system_psi from "./testReport/system_psi"
import dmr from "./testReport/dmr"
import final_check1 from "./testReport/final_check1"
import final_check2 from "./testReport/final_check2"

import rv_pressdrop from "./testReport/rv_pressdrop"
import rv_opened from "./testReport/rv_opened"
import final_rvPressdrop from "./testReport/final_rvPressdrop"
import final_rvOpened from "./testReport/final_rvOpened"*/

/*xvba*/
/*import air_opened from "./testReport/air_opened"
import final_air_opened from "./testReport/final_air_opened"
import check_pressdrop from "./testReport/check_pressdrop"
import final_check_pressdrop from "./testReport/final_check_pressdrop"*/

/*ag*/
/*
import pipesize from "./testReport/pipesize"
import physical_separation from "./testReport/physical_separation"
import final_pipesize from "./testReport/final_pipesize"
import final_physical_separation from "./testReport/final_physical_separation"*/


/*reducers for stop app*/
import addressID from "./stopsApp/addressID"
import reportID from "./stopsApp/reportID"
import assemblyID from "./stopsApp/assemblyID"
import assemblylist from "./stopsApp/assembly_list";
import stoplist from "./stopsApp/stoplist.js"
import dispatchDate from "./stopsApp/date.js"
import stopID from "./stopsApp/stopID.js"
import notTestedBoolean from "./stopsApp/notTestedBoolean.js"

/*invoice app*/
import invoiceID from "./invoiceApp/invoiceID"
import itemlist from "./invoiceApp/itemlist"
import optionslist from "./invoiceApp/optionslist"
import billingID from "./invoiceApp/billingID"
import itemID from "./invoiceApp/itemID"

/*assembly app*/
import assembly_id from "./assembly/id"
import assembly_location from "./assembly/location"
import assembly_hazard from "./assembly/hazard"
import assembly_make from "./assembly/make"
import assembly_model from "./assembly/model"
import assembly_serial from "./assembly/serial"
import assembly_size from "./assembly/size"
import test_type from "./assembly/test_type"
import assembly_type from "./assembly/type"

/*Approved*/
import approved_installation from "./approved/installation"
import approved_airgap from "./approved/airgap"
import approved_assembly from "./approved/assembly"
import approved_orientation from "./approved/orientation"
import approved_usc from "./approved/usc"

/*system*/
import system_dmr from "./system/dmr"
import system_psi from "./system/psi"

/*initial results*/
import initial_pass from "./initialResults/pass"
import initial_fail from "./initialResults/fail"
import restored from "./initialResults/restored"

/* remarks*/
import so1 from "./remarks/so1"
import so2 from "./remarks/so2"
import upstream from "./remarks/upstream"
import so1_rusted from "./remarks/so1_rusted"
import so2_rusted from "./remarks/so2_rusted"
import upstream_rusted from "./remarks/upstream_rusted"
import comment from "./remarks/comment"

/*Number pad*/
import target from "./numberPad/target"

/*parts*/
import ai_disc from "./parts/ai_disc"
import ai_spring from "./parts/ai_spring"
import check_disc from "./parts/check_disc"
import check_spring from "./parts/check_spring"
import diaphragm from "./parts/diaphragm"
import disc from "./parts/disc"
import ff from "./parts/float"
import guide from "./parts/guide"
import module from "./parts/module"
import oring from "./parts/oring"
import rubber_kit from "./parts/rubber_kit"
import seat from "./parts/seat"
import spring from "./parts/spring"

/*final results*/
import cleaned from "./finalResults/cleaned"
import repaired from "./finalResults/repaired"
import final_pass from "./finalResults/pass"
import final_fail from "./finalResults/fail"

/*DC*/
import check1 from "./dc/check1"
import check1_tight from "./dc/check1_tight"
import check1_leaked from "./dc/check1_leaked"
import check2_tight from "./dc/check2_tight"
import check2_leaked from "./dc/check2_leaked"
import check2 from "./dc/check2"
import final_check1 from "./dc/final_check1"
import final_check2 from "./dc/final_check2"
import final_check1_tight from "./dc/final_check1_tight"
import final_check1_leaked from "./dc/final_check1_leaked"
import final_check2_tight from "./dc/final_check2_tight"
import final_check2_leaked from "./dc/final_check2_leaked"
import dc_typeII from "./dc/typeII"

/*RP*/
import rp_opened_at from "./rp/opened_at"
import pressdrop from "./rp/pressdrop"
import rp_did_not_open from "./rp/rp_did_not_open"
import rp_typeII from "./rp/typeII"
import final_rp_opened_at from "./rp/final_opened"
import final_pressdrop from "./rp/final_pressdrop"
import final_rp_did_not_open from "./rp/final_rp_did_not_open"

/*XVBA */
import ai_did_not_open from "./xvba/ai_did_not_open"
import ai_opened_at from "./xvba/ai_opened_at"
import ai_opened_fully from "./xvba/ai_opened_fully"
import check_valve from "./xvba/check_valve"
import check_valve_failed from "./xvba/check_valve_failed"
import final_ai_did_not_open from "./xvba/final_ai_did_not_open"
import final_ai_opened_at from "./xvba/final_ai_opened_at"
import final_ai_opened_fully from "./xvba/final_ai_opened_fully"
import final_check_valve from "./xvba/final_check_valve"
import final_check_valve_failed from "./xvba/final_check_valve_failed"

/*ag*/
import pipesize from "./ag/pipesize"
import physical_separation from "./ag/physical_separation"

/* removed- replaced*/
import removed from "./removed/removed"
import lines from "./removed/lines"
import new_serial from "./replaced/new_serial"

/*map*/
import waypoints from "./map/waypoints"
import startpoint from "./map/start"
import endpoint from "./map/end"
import route from "./map/route"
import distance_matrix from "./map/distance_matrix"
import stop_order from "./map/order"
import time_windows from "./map/time_windows"
import services_times from "./map/services_times"
import routed from "./map/routed"


/*settings*/
import tester_addresses from "./settings/tester_addresses"
import default_address from "./settings/default_address"

export default combineReducers({
  waypoints, startpoint, endpoint, route,  distance_matrix, stop_order, time_windows, routed, services_times,
    tester_addresses, default_address, 
    waypoints,
    assemblyID, 
    removed, lines, new_serial,
    rp_typeII,
    pipesize, physical_separation,
    ai_did_not_open,
    ai_opened_at,
    ai_opened_fully,
    check_valve,
    check_valve_failed,
    final_ai_did_not_open,
    final_ai_opened_at,
    final_ai_opened_fully,
    final_check_valve,
    final_check_valve_failed,
    rp_opened_at,
    pressdrop, rp_did_not_open,
    final_rp_did_not_open,
    final_rp_opened_at,
    final_pressdrop,
    dc_typeII, 
    check1,
    check1_tight,
    check1_leaked,
    check2_tight ,
    check2_leaked,
    check2 ,
    final_check1,
    final_check2 ,
    final_check1_tight,
    final_check1_leaked ,
    final_check2_tight ,
    final_check2_leaked ,

    ai_disc, ai_spring, check_disc,
    check_spring, diaphragm, disc,
    ff, guide, module, oring, rubber_kit, seat, spring,

    cleaned, repaired,  final_pass, final_fail,
    target,
    so1, so2, upstream, so1_rusted, so2_rusted, upstream_rusted, comment,
    initial_pass,
    initial_fail,
    restored,
    system_dmr,
    system_psi,
    approved_installation,
    approved_airgap, 
    approved_assembly, 
    approved_orientation,
    approved_usc, 
  session,
  addressID, 
  reportID, 
  assemblylist, 
  stoplist,
  dispatchDate,
  stopID, 
  notTestedBoolean, 

  invoiceID, 
  itemlist, 
  optionslist, 
  billingID, 
  itemID,
  assembly_id,
  assembly_location,
  assembly_hazard,
  assembly_make,
    assembly_model,
    assembly_serial,
    assembly_size,
    test_type,
   assembly_type




  /*invoice, 
  assembly,
  message, 
  confinedEntry, 
  googleRoutes, 
  lineitems, 
  numberPad, 
  updatedVal,
  testlist,
  assemblylist, 
   invoiceItems, 
   billingAddress, 
   selectedDate,
   confinedEntryList, 
   testerslist, 
   report, 
   assembly_id, 
   assembly_type, 
   report_id, 
   check1_psid, 
   check2_psid, 
   system_psi, 
   dmr, 
   final_check1, 
   final_check2, 
   rv_pressdrop,
   rv_opened, 
   final_rvPressdrop, 
   final_rvOpened, 
   air_opened, 
   final_air_opened, 
   check_pressdrop, 
   final_check_pressdrop, 
   pipesize, 
   physical_separation, 
   final_pipesize, 
   final_physical_separation*/
})