
export const requestStopServiceTime = (stopID) => (
	fetch("backflow/getter/servicetime?" + new URLSearchParams({
		stopID : stopID
	}) , {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
);