import {
  SET_SERVICES_TIMES, CLEAR_SERVICES_TIMES
} from "../../actions/map";


const _null = []; 
             
export default (state = _null, { type, list}) => {
  Object.freeze(state);
  switch (type) {
    case SET_SERVICES_TIMES:
      return list;
    case CLEAR_SERVICES_TIMES:
      return _null;
    case '':
      return _null
    default:
      return state;
  }
};