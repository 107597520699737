
export const SET_CHECK1 = "SET_CHECK1"
export const SET_CHECK1_TIGHT = "SET_CHECK1_TIGHT"
export const SET_FINAL_CHECK1 = "SET_FINAL_CHECK1"
export const SET_FINAL_CHECK2 = "SET_FINAL_CHECK2"
export const SET_DC_TYPE_II = "SET_DC_TYPE_II"

export const SET_CHECK1_LEAKED = "SET_CHECK1_LEAKED"
export const SET_CHECK2 = "SET_CHECK2"
export const SET_CHECK2_TIGHT = "SET_CHECK2_TIGHT"
export const SET_CHECK2_LEAKED = "SET_CHECK2_LEAKED"
export const SET_FINAL_CHECK1_TIGHT = "SET_FINAL_CHECK1_TIGHT"
export const SET_FINAL_CHECK1_LEAKED = "SET_FINAL_CHECK1_LEAKED"
export const SET_FINAL_CHECK2_TIGHT = "SET_FINAL_CHECK2_TIGHT"
export const SET_FINAL_CHECK2_LEAKED = "SET_FINAL_CHECK2_LEAKED"

export const CLEAR_CHECK1 = "CLEAR_CHECK1"
export const CLEAR_CHECK2 = "CLEAR_CHECK1"
export const CLEAR_FINAL_CHECK1 = "CLEAR_FINAL_CHECK1"
export const CLEAR_FINAL_CHECK2 = "CLEAR_FINAL_CHECK1"
export const CLEAR_CHECK1_TIGHT = "CLEAR_CHECK1_TIGHT"
export const CLEAR_CHECK1_LEAKED = "CLEAR_CHECK1_LEAKED"
export const CLEAR_CHECK2_TIGHT = "CLEAR_CHECK2_TIGHT"
export const CLEAR_CHECK2_LEAKED = "CLEAR_CHECK2_LEAKED"
export const CLEAR_FINAL_CHECK1_TIGHT = "CLEAR_CHECK1_TIGHT"
export const CLEAR_FINAL_CHECK1_LEAKED = "CLEAR_CHECK1_LEAKED"
export const CLEAR_FINAL_CHECK2_TIGHT = "CLEAR_CHECK2_TIGHT"
export const CLEAR_FINAL_CHECK2_LEAKED = "CLEAR_CHECK2_LEAKED"
export const CLEAR_DC_TYPE_II = "CLEAR_DC_TYPE_II"

const clearCheck1 = () =>  ({ type: CLEAR_CHECK1 })
const clearCheck2 = () =>  ({ type: CLEAR_CHECK2 })
const clearFinalCheck1 = () =>  ({ type: CLEAR_FINAL_CHECK1 })
const clearFinalCheck2 = () =>  ({ type: CLEAR_FINAL_CHECK2 })

const clearFinalCheck2Tight = () =>  ({ type: CLEAR_FINAL_CHECK2_TIGHT })
const clearFinalCheck2Leaked = () =>  ({ type: CLEAR_FINAL_CHECK2_LEAKED })
const clearFinalCheck1Tight = () =>  ({ type: CLEAR_FINAL_CHECK1_TIGHT })
const clearFinalCheck1Leaked = () =>  ({ type: CLEAR_FINAL_CHECK1_LEAKED })
const clearCheck2Tight = () =>  ({ type: CLEAR_CHECK2_TIGHT })
const clearCheck2Leaked = () => ({ type: CLEAR_CHECK2_LEAKED })
const clearCheck1Tight = () =>  ({ type: CLEAR_CHECK1_TIGHT })
const clearCheck1Leaked = () =>  ({ type: CLEAR_CHECK1_LEAKED })
const clearDcTypeII = () => ({ type: CLEAR_DC_TYPE_II })

const setCheck1 = (val) => ({ type: SET_CHECK1, val })
const setTypeII = (truefalse) => ({ type: SET_DC_TYPE_II, truefalse})
const setCheck1Tight = (truefalse) => ({ type: SET_CHECK1_TIGHT, truefalse })
const setCheck1Leaked = (truefalse) => ({ type: SET_CHECK1_LEAKED, truefalse })
const setCheck2Tight = (truefalse) => ({ type: SET_CHECK2_TIGHT, truefalse })
const setCheck2Leaked = (truefalse) => ({ type: SET_CHECK2_LEAKED, truefalse })
const setFinalCheck1Tight = (truefalse) => ({ type: SET_FINAL_CHECK1_TIGHT, truefalse })
const setFinalCheck1Leaked = (truefalse) => ({ type: SET_FINAL_CHECK1_LEAKED, truefalse })
const setFinalCheck2Tight = (truefalse) => ({ type: SET_FINAL_CHECK2_TIGHT, truefalse })
const setFinalCheck2Leaked = (truefalse) => ({ type: SET_FINAL_CHECK2_LEAKED, truefalse })
const setCheck2 = (val) =>  ({ type: SET_CHECK2, val })
const setFinalCheck1 = (val) => ({ type: SET_FINAL_CHECK1, val })
const setFinalCheck2 = (val) =>  ({ type: SET_FINAL_CHECK2, val })

export const clearDoubleCheck = () => dispatch =>{
    return new Promise((resolve, reject) => {
        let p1 = dispatch(clearCheck1())
        let p2 = dispatch(clearCheck2())
        let p3 = dispatch(clearFinalCheck1())
        let p4 = dispatch(clearFinalCheck2())
        let p5 = dispatch(clearCheck1Tight())
        let p6 = dispatch(clearCheck1Leaked())
        let p7 = dispatch(clearCheck2Tight())
        let p8 = dispatch(clearCheck2Leaked())
        let p9 = dispatch(clearFinalCheck1Tight())
        let p10 = dispatch(clearFinalCheck1Leaked())
        let p11 = dispatch(clearFinalCheck2Tight())
        let p12 = dispatch(clearFinalCheck2Leaked())
        let p13 = dispatch(clearDcTypeII() )

        Promise.all([p1, p2, p3, p4,
            p5, p6, p7, p8, p9, p10, p11, p12, p13]).then((data, err) => {
            resolve()
        })
    })
}


export const reduceTypeII = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setTypeII(truefalse))
        resolve()
    })
}
export const reduceCheck1Tight = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setCheck1Tight(truefalse))
        resolve()
    })
}
export const reduceCheck1Leaked = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setCheck1Leaked(truefalse))
        resolve()
    })
}
export const reduceCheck2Tight = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setCheck2Tight(truefalse))
        resolve()
    })
}
export const reduceCheck2Leaked = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setCheck2Leaked(truefalse))
        resolve()
    })
}
export const reduceFinalCheck2Tight = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalCheck2Tight(truefalse))
        resolve()
    })
}
export const reduceFinalCheck2Leaked = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalCheck2Leaked(truefalse))
        resolve()
    })
}
export const reduceFinalCheck1Tight = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalCheck1Tight(truefalse))
        resolve()
    })
}
export const reduceFinalCheck1Leaked = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalCheck1Leaked(truefalse))
        resolve()
    })
}
export const reduceCheck1 = (val) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setCheck1(val))
        resolve()
    })
}

export const reduceCheck2 = (val) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setCheck2(val))
        resolve()
    })
}
export const reduceFinalCheck1 = (val) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalCheck1(val))
        resolve()
    })
}
export const reduceFinalCheck2 = (val) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalCheck2(val))
        resolve()
    })
}