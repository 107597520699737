/* eslint-disable no-unused-vars */
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Badge from 'react-bootstrap/Badge'
import InputGroup from 'react-bootstrap/InputGroup'

import { useSelector, useDispatch } from "react-redux";
import {
	reduceRemoved, reduceLines, reduceNewSerial
} from "../../../actions/removed"
import {
	reduceTarget
} from "../../../actions/NumberPad"

export default function Airgap() {
	const dispatch = useDispatch();
	const removed = useSelector((state) => state.removed)
	const lines = useSelector((state) => state.lines)
	const new_serial = useSelector( (state) => state.new_serial)

	return (
		<Container>
			<Row>
				<Col>
					<strong> Removed </strong>
				</Col>

			</Row>

			<Row>
				<Col>
					<InputGroup size="sm">
						<Form.Check
							label="Assembly Removed"
							checked={removed}
							onChange={
								(event) => {
										dispatch(reduceRemoved( event.target.checked))

								}}
						/>
					</InputGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<InputGroup size="sm">
						<Form.Check
							label="Lines Capped"
							checked={lines}
							onChange={
								(event) => {
									dispatch(reduceLines(event.target.checked))

								}}
						/>
					</InputGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<InputGroup size="sm">
						<InputGroup.Text>Replaced by serial #</InputGroup.Text>
						<Form.Control
							value={new_serial}
							onChange={
								(event) => {
									dispatch(reduceNewSerial(event.target.value) )
								}
							}

						/>
					</InputGroup>
				</Col>
			</Row>

		</Container>
	)
}