import * as server from "../util/stopsServer";

export const  SET_REPORT_ID =  "SET_REPORT_ID"
export const CLEAR_REPORT_ID = "CLEAR_REPORT_ID"
export const SET_ASSEMBLY_ID = "SET_ASSEMBLY_ID"
export const CLEAR_ASSEMBLY_ID = "CLEAR_ASSEMBLY_ID"

export const SET_ADDRESS_ID = "SET_ADDRESS_ID"
export const CLEAR_ADDRESS_ID = "CLEAR_ADDRESS_ID"
export const SET_ASSEMBLY_LIST = "SET_ASSEMBLY_LIST"
export const CLEAR_ASSEMBLY_LIST = "CLEAR_ASSEMBLY_LIST"
export const SET_STOP_LIST = "SET_STOP_LIST"
export const CLEAR_STOP_LIST = "CLEAR_STOP_LIST"
export const SET_DISPATCH_DATE = "SET_DISPATCH_DATE"
export const SET_STOP_ID = "SET_STOP_ID"
export const CLEAR_STOP_ID = "CLEAR_STOP_ID"
export const NOT_TESTED_TRUE = "NOT_TESTED_TRUE"
export const NOT_TESTED_FALSE = "NOT_TESTED_FALSE"



const receiveDate = (date) => ({
	type: SET_DISPATCH_DATE,
	date
})

const receiveReportID = (id) => ({
	type: SET_REPORT_ID, 
	id
})

const receiveAssemblyID = (id) => ({
	type: SET_ASSEMBLY_ID,
	id
})
const receiveAddressID = (id) => ({
	type: SET_ADDRESS_ID, 
	id
})
const receiveStoplist = (list) => ({
	type: SET_STOP_LIST, 
	list
})
const receiveAssemblylist = (list) => ({
	type: SET_ASSEMBLY_LIST, 
	list
})
const receiveStopID = (id) => ({
	type: SET_STOP_ID,
	id
})
const nullStopID = () => ({
	type: CLEAR_STOP_ID
})
const nullReportID = () => ({
	type: CLEAR_REPORT_ID
})
const nullAssemblyID = () => ({
	type: CLEAR_ASSEMBLY_ID
})
const nullAddressID = () => ({
	type: CLEAR_ADDRESS_ID
})

const emptyStoplist = () => ({
	type: CLEAR_STOP_LIST
})
const emptyAssemblylist = () => ({
	type: CLEAR_ASSEMBLY_LIST
})

const falseNotTested = () => ({ type: NOT_TESTED_FALSE} )
const trueNotTested = () => ({ type: NOT_TESTED_TRUE})

export const showNotTested = () => dispatch => {
	return new Promise(async (resolve, reject) => {
		dispatch(trueNotTested() );
		resolve()
	})
}

export const hideNotTested = () => dispatch => {
	return new Promise(async (resolve, reject) => {
		dispatch( falseNotTested() );
		resolve()
	})
}


export const determineNewDate = (date) => dispatch => {
	return new Promise(async (resolve, reject) => {
		dispatch(receiveDate(date));
		resolve()
	})
}
export const setReportID = (id) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( receiveReportID(id) ); 
		resolve()
	})
}
export const setAssemblyID = (id) => dispatch => {
	return new Promise(async (resolve, reject) => {
		dispatch(receiveAssemblyID(id));
		resolve()
	})
}

export const setStopID = (id) => dispatch => {
	return new Promise(async (resolve, reject) => {
		dispatch(receiveStopID(id));
		resolve()
	})
}
export const clearStopID = () => dispatch => {
	return new Promise(async (resolve, reject) => {
		dispatch(nullStopID());
		resolve()
	})
}

export const clearReportID = () => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( nullReportID() ); 
		resolve()
	})
}
export const clearAssemblyID = () => dispatch => {
	return new Promise(async (resolve, reject) => {
		dispatch(nullAssemblyID());
		resolve()
	})
}
export const setAddressID = (id) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( receiveAddressID(id) ); 
		resolve()
	})
}
export const clearAddressID = () => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( nullAddressID() ); 
		resolve()
	})
}
export const setStoplist = (list) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( receiveStoplist(list) ); 
		resolve()
	})
}

export const clearStoplist = () => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( emptyStoplist() ); 
		resolve()
	})
}
export const setAssemblylist = (list) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( receiveAssemblylist(list) ); 
		resolve()
	})
}
export const clearAssemblylist = () => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( emptyAssemblylist() ); 
		resolve()
	})
}
 
export const requestReportDetails = () => {
	return new Promise(async(resolve, reject) =>{
		const response = await 
		resolve()
	})
}


export const requestStoplist = (date) => dispatch => {
	return new Promise(async (resolve, reject) => {
		const response = await server.requestStoplist(date); 
		const data = await response.json();
		if (response.ok) {
			dispatch(receiveStoplist(JSON.parse(data).list));
			resolve()
		}
	})
}

export const requestStopsRoute = (date) => dispatch => {
	return new Promise(async (resolve, reject) => {
		const response = await server.requestStopsRoute(date); 
		const data = await response.json();
		if (response.ok) {
			console.log("Route: ", JSON.parse(data).route)
			resolve(JSON.parse(data).route[0])
		}
	})
}

export const requestAddressDetails = (addressID) => {
	return new Promise(async (resolve, reject) => {
		const response = await server.requestAddressDetails(addressID);
		const data = await response.json();
		if (response.ok) {
			resolve(JSON.parse(data).obj )
		}
	})
}

export const requestStopServices = (stopID) => dispatch => {
	return new Promise(async (resolve, reject) => {
		let response = await server.requestStopServices(stopID); 
		let data = await response.json();
		if (response.ok) {
			dispatch(receiveAssemblylist(JSON.parse(data).list))
			resolve()
		}
	})
}

export const cleanStopServices = () => dispatch => {
	return new Promise(async (resolve, reject) => {	
		dispatch(emptyAssemblylist())
		resolve()
	})
}


export const requestAssemblyDetails = (assemblyID) => {
	return new Promise(async (resolve, reject) => {
		let response = await server.requestAssemblyDetails(assemblyID);
		let data = await response.json();
		if (response.ok) {
			resolve(JSON.parse(data).obj)
		}
	})
}

export const createAssembly = (stopID, addressID) => {
	return new Promise(async (resolve, reject) => {
		let response = await server.createAssembly(stopID, addressID);
		let data = await response.json();
		if (response.ok) {
			resolve(JSON.parse(data).obj)
		}
	})
}

export const updateStopState = (stopID, newState, reason) => {
	return new Promise(async (resolve, reject) => {
		let response = await server.updateStopState(stopID, newState, reason);
		let data = await response.json();
		if (response.ok) {
			resolve()
		}
	})
}

export const  updateServiceState = ( truefalse, reason, serviceID, applyToAll) =>{
	return new Promise(async (resolve, reject) => {
		let response = await server.updateServiceState( truefalse, reason, serviceID, applyToAll );
		let data = await response.json();
		if (response.ok) {
			resolve()
		}
	})

}



