import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Badge from 'react-bootstrap/Badge'
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	reduceFinalCheck1,
	reduceFinalCheck2,
	reduceFinalCheck1Tight,
	reduceFinalCheck1Leaked,
	reduceFinalCheck2Tight,
	reduceFinalCheck2Leaked,
} from "../../../actions/dc"

import {
	reduceTarget
} from "../../../actions/NumberPad"

function Editable() {
	const check1 = useSelector((state) => state.final_check1)
	const check2 = useSelector((state) => state.final_check2)
	const check1_tight = useSelector((state) => state.final_check1_tight)
	const check2_tight = useSelector((state) => state.final_check2_tight)
	const check1_leaked = useSelector((state) => state.final_check1_leaked)
	const check2_leaked = useSelector((state) => state.final_check2_leaked)
	const typeII = useSelector((state) => state.dc_typeII)
	
	const dispatch = useDispatch()

	useEffect(() => {
		if (check1 != null) {
			if (check1 >= 1.0) {
				dispatch(reduceFinalCheck1Tight(true))
				dispatch(reduceFinalCheck1Leaked(false))
			} else {
				dispatch(reduceFinalCheck1Tight(false))
				dispatch(reduceFinalCheck1Leaked(true))
			}
		}
		if (check2 != null) {
			if (check2 >= 1.0) {
				dispatch(reduceFinalCheck2Tight(true))
				dispatch(reduceFinalCheck2Leaked(false))
			} else {
				dispatch(reduceFinalCheck2Tight(false))
				dispatch(reduceFinalCheck2Leaked(true))
			}
		}
	}, [check1, check2])

	return (
		<>
			<Row xs={1} sm={1} md={2} lg={2}>
				<Col>

					{
						typeII ?
							<>
							</>

						: 

							<InputGroup>
								<InputGroup.Text>
									#1
								</InputGroup.Text>
								<Form.Control
									value = { check1}
									readOnly
									onFocus={
										(event) => {
											dispatch(reduceTarget(
												{
													name: "Final CHECK1",
													slice: reduceFinalCheck1,
													target: event.target, 
													current: check1 
												}
											))
										}
									}
								/>
								{
									check1_tight ?
										<Badge bg="primary">Tight</Badge>
										:
										<> </>
								}
								{
									check1_leaked ?
										<Badge bg="secondary"> Leaked </Badge>
										:
										<> </>
								}
							</InputGroup>
					}
					

					<InputGroup>
						<InputGroup.Text>
							#2
						</InputGroup.Text>

						<Form.Control
							value = {check2} 
							readOnly
							onFocus={
								(event) => {
									dispatch(reduceTarget(
										{
											name: "FINAL CHECK2",
											slice: reduceFinalCheck2,
											target: event.target, 
											current: check2
										}
									))
								}
							}
						/>

						{
							check2_tight ?
								<Badge bg="primary">Tight</Badge>
								:
								<> </>
						}
						{
							check2_leaked ?
								<Badge bg="secondary"> Leaked </Badge>
								:
								<> </>
						}



					</InputGroup>
				</Col>
			</Row>
		</>

	)

}

export default function DoubleCheck() {
	const dispatch = useDispatch()

	return (
		<Container>
			<Row>
				<Col>
					<strong> Double Check </strong>
				</Col>
			</Row>

			<Editable />


		</Container>
	)

}