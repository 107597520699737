import {
  SET_ASSEMBLY_SERIAL, CLEAR_ASSEMBLY_SERIAL
} from "../../actions/assemblyApp";

const _null = null; 
             
export default (state = _null, { type, serial }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ASSEMBLY_SERIAL:
      return serial;
    case CLEAR_ASSEMBLY_SERIAL: 
      return _null
    default:
      return state;
  }
};