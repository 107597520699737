import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef, useCallback, useContext} from "react";
import {useSelector, useDispatch} from "react-redux";
import ReadMore from "./Content"
import Stack from 'react-bootstrap/Stack';
import {
	requestAddressDetails,
	requestStopServices,
	cleanStopServices,
	setAddressID,
	clearAddressID,
	setStopID,
	clearStopID, 
	updateStopState, 
	requestStoplist, clearStoplist, showNotTested, hideNotTested
} from "../../actions/stopsApp"
import { format } from "date-fns"
import AssemblyList from "./AssemblyList"
import StopBody from "./StopBody"
import GoogleApple from "./GoogleApple"
const PINK = 'rgba(255, 192, 203, 0.6)';
const BLUE = 'rgba(0, 0, 255, 0.6)';

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      style={{ backgroundColor: isCurrentEventKey ? PINK : BLUE }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

export default function StopCard(props) {
	const [addressLoading, setAddressLoading] = useState(true)
	const [myAddress, setMyAddress] = useState(null)
	const [timeBadge, setTimeBadge] = useState(<> </>)
	const [completed, setCompleted] = useState(false)
	const [incomplete, setIncomplete] = useState(false)
	const [changeLoading, setChangeLoading] = useState( false) ; 
	const mydate = useSelector((state) => state.dispatchDate)
	const dispatch = useDispatch()
	const [commentlength, setCommentLength] = useState(250)
	function formatTime(time24) {
		// Split the time string into hours and minutes
		 const [hours, minutes] = time24.split(':').map(Number);
		// Determine AM/PM
		const ampm = hours >= 12 ? 'PM' : 'AM';

	  // Convert to 12-hour format
		const hours12 = hours % 12 || 12;

		  // Format the time string
		 return `${hours12}:${minutes.toString().padStart(2, '0')} ${ampm}`;
	}

	useEffect(() => {
		if (props.stop.startTime != "08:00" && props.stop.endTime != "16:00") {
			if(props.stop.startTime == props.stop.endTime){
				setTimeBadge(<Badge bg= "danger">{ formatTime(props.stop.startTime) }  </Badge>)
			}else{
				setTimeBadge(<Badge bg= "danger">{ formatTime( props.stop.startTime)} - {formatTime( props.stop.endTime) }  </Badge>)
			}
			
		} else {
			setTimeBadge(<> </>)
		}
	}, [])

	useEffect(()=>{
		//load up stop address details
		if (props.stop.addressID != null) {
			setAddressLoading(true)
			requestAddressDetails(props.stop.addressID).then((data, err) => {
				setMyAddress(data)
				setAddressLoading(false)
			})
		}
	}, [])

	useEffect(()=>{
		if(props.stop.stopStatus == "COMPLETED" ){
			setCompleted(true)
		}else if(props.stop.stopStatus == "NOT TESTED"){
			setIncomplete(true)
		}
	}, [])


	return(
		<>
			{
				completed || incomplete ?

					<Accordion.Item eventKey={props.stop.addressID} >
							<Accordion.Header bsPrefix = "stop-card" >
								<Container>
							
								<Row>

										<Col>
										{
											addressLoading ?
													<Spinner />
												
												:
												<>
											
												<strong>{myAddress.location_name}</strong> {timeBadge} <br /> 
												{myAddress.street}, {myAddress.city}, {myAddress.state}, { myAddress.zipcode}
												</>
										}
									</Col>
									
								</Row>
								<Row>
									<Col>
										{
											addressLoading ?
												<Spinner />
												:
												<p>
													
												</p>
										}
									</Col>
									</Row>
									<Row>
										<Col>
											<>{
												props.stop.comment ?
													<>
														{
															props.stop.comment.length >= commentlength ?
																	<>  {String(props.stop.comment).slice(0, commentlength)}... </>
																:
																	<>  {String(props.stop.comment).slice(0, commentlength)} </>
															}
															
													</>
													:
													<></>
												}</>
										</Col>
									</Row>
										<Row>
										
									<Col>
										<br/>
										<Stack direction="horizontal">
											
											
										{
											completed ?
												<Badge bg = "success"> {props.stop.stopStatus} </Badge>
											: 
												<>
												</>
										}
										{
											incomplete ?
												<>
												<Badge bg = "secondary"> Incomplete </Badge>
												
												</>
											: 
												<>
												</>
										}
										
										</Stack>
									</Col>
								</Row>
								</Container>
						
							</Accordion.Header>
							<Accordion.Body
								onEnter={
									() => {
										//load services for this stop
										let p1 = dispatch(requestStopServices(props.stop.stopID))
										let p2 = dispatch(setAddressID(props.stop.addressID))
										let p3 = dispatch(setStopID(props.stop.stopID))
									}
								}
								onExited={
									() => {
										//clean up services 
										let p1 = dispatch( cleanStopServices() )
										let p2 = dispatch(clearAddressID())
										let p3 = dispatch( clearStopID())
									}
								}
							>
								<Row>
									<Col>
										<>{
											props.stop.comment ?
												<>
													{
														props.stop.comment.length <= commentlength ?
															<>{String(props.stop.comment).slice(commentlength, props.stop.comment.length)} </>
															:
															<>  ...{String(props.stop.comment).slice(commentlength, props.stop.comment.length)} </>
													}
												</>
												:
												<> </>
										}</>
									</Col>
								</Row>
							
								<Row className = "client-info">
									<Col>
										<p>
										{
											addressLoading ?
												<Spinner />
												:
												<>
														{myAddress.contact_name} <br/> 
														<a href={ `tel:${myAddress.contact_phone  }`}>{myAddress.contact_phone  }
														</a>
												</>
											}
										</p>
									</Col>
										<Col xs={5}sm md lg={1}>
										<GoogleApple address={myAddress} />
									</Col>
									
								</Row>
				
								<Row>
									<Col>
										<StopBody />
									</Col>
								</Row>
								<Row>
									
								</Row>
								<hr/>
								<Row>
									<Col xs={7} sm md lg = {7}>
										<small>
											Scheduled By: <br/>{props.stop.scheduler}
										</small>
									</Col>
										
									<Col>
										{

											changeLoading ?
												<Spinner />
											: 
												<ButtonGroup >
											
											<Button 
												size="sm" 
												variant="success"
												onClick = {
													()=>{
														setChangeLoading(true)
														updateStopState( props.stop.stopID, "COMPLETED").then( (data, err) =>{
															dispatch( clearStoplist() ).then((data, err) =>{
																dispatch(requestStoplist(mydate)).then((data, err) =>{
																	setChangeLoading(false)
																})
															})
																
														})
													}
												}
											>
												Send to office
											</Button>
											</ButtonGroup>
										}
									</Col>
								</Row>
							</Accordion.Body>

					</Accordion.Item>
					
				: 
					<Accordion.Item eventKey={props.stop.addressID} >
							<Accordion.Header >
								<Container>
								<Row>
										<Col xs={12} sm={12} md lg={12}>
										{
											addressLoading ?
													<Spinner />
												
												:
												<>
												
													<strong>{myAddress.location_name}</strong> {timeBadge}<br/>
													{myAddress.street}, {myAddress.city}, {myAddress.state}, { myAddress.zipcode}
												</>
										}
									</Col>
								
								</Row>
								<Row>
									<Col>
										{
											addressLoading ?
												<Spinner />
												:
												<p>
													
												</p>
										}
									</Col>
									</Row>
									<Row>
										<Col>
											<>{
												props.stop.comment ?
													<>
														{
															props.stop.comment.length >= 90 ?
																	<>  {String(props.stop.comment).slice(0, 90)}... </>
																:
																	<>  {String(props.stop.comment).slice(0, 90)} </>
															}
													</>
													:
													<></>
												}</>
										</Col>
									</Row>
									<Row>
									
										<Col>
											<br/>
											<Badge bg = "secondary"> Incomplete</Badge>
										</Col>
									
									</Row>
								</Container>
						
							</Accordion.Header>
							<Accordion.Body
								onEnter={
									() => {
										//load services for this stop
										let p1 = dispatch(requestStopServices(props.stop.stopID))
										let p2 = dispatch(setAddressID(props.stop.addressID))
										let p3 = dispatch(setStopID(props.stop.stopID))
									}
								}
								onExited={
									() => {
										//clean up services 
										let p1 = dispatch( cleanStopServices() )
										let p2 = dispatch(clearAddressID())
										let p3 = dispatch( clearStopID())
									}
								}
							>
								<Row>
									<Col>
										<u>{
											props.stop.comment ?
												<>
													{
														props.stop.comment.length <= 90 ?
															<>{String(props.stop.comment).slice(90, props.stop.comment.length)} </>
															:
															<>  ...{String(props.stop.comment).slice(90, props.stop.comment.length)} </>
													}
												</>
												:
												<> </>
										}</u>
									</Col>
								</Row>
								<Row className = "client-info">
									<Col xs={5}s>
										<p>
										{
											addressLoading ?
												<Spinner />
												:
												<p>
														{myAddress.contact_name} <a href={ `tel:${myAddress.contact_phone  }`}>{myAddress.contact_phone  }
														</a>
												</p>
											}
										</p>
									</Col>
									<Col xs={6} sm md lg={1}>
										<GoogleApple address={myAddress} />
									</Col>
								</Row>

								<Row>
									
										<StopBody />
								
								</Row>
								<Row>
									
								</Row>
								<hr/>
								<Row>
									<Col xs={5} sm md={8} lg = {8}>
										<small>
											Scheduled By: <br/>{props.stop.scheduler}
										</small>
									</Col>
							
									<Col>
										{

											changeLoading ?
												<Spinner />
											: 
												<ButtonGroup>
											
											<Button 
												
												variant="success"
												onClick = {
													()=>{
														setChangeLoading(true)
														updateStopState( props.stop.stopID, "COMPLETED").then( (data, err) =>{
															dispatch( clearStoplist() ).then((data, err) =>{
																dispatch(requestStoplist(mydate)).then((data, err) =>{
																	setChangeLoading(false)
																})
															})
																
														})
													}
												}
											>
												Send to office
											</Button>
											</ButtonGroup>
										}
									</Col>
								</Row>
							</Accordion.Body>

					</Accordion.Item>
			}
		
		</>
	)
}