import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    addTesterAddress, 
    removeTesterAddress, 
    getTesterAddressList
} from "../../actions/settings"

export default function NewAddress(props){
	const [show, setShow] = useState(true)
	const dispatch = useDispatch()
	const [label, setLabel] = useState('')
	const [street, setStreet] = useState('')
	const [city, setCity] = useState('')
	const [state, setState] = useState('')
	const [zipcode, setZipcode] = useState('')

	const addAddress = (event )=>{
		event.preventDefault() 
		let obj = {}; 
		obj.label = label; 
		obj.street = street; 
		obj.city = city; 
		obj.state = state; 
		obj.zipcode = zipcode; 
		addTesterAddress( obj ) .then( (data, err) =>{
			dispatch( getTesterAddressList() ).then((data, err) =>{
				props.closeModal()
			})
		})
	}

	return(
		<>
			<Modal show = {show} centered size= "lg">

				<Modal.Header closeButton onHide = {( ) =>{props.closeModal() } }> 
					New Address
				</Modal.Header>
				<Form onSubmit = { addAddress }>
				<Modal.Body>
					<Form.Label> Label </Form.Label>
					<Form.Control
						value = { label }
						onChange = { (event) => setLabel(event.target.value)}
						required
					/>
					<Form.Label> Street </Form.Label>
					<Form.Control 
						value = { street }
						onChange = { (event) => setStreet(event.target.value)}
						required 
					/>
					<Form.Label> City</Form.Label>
					<Form.Control 
						value = { city }
						onChange = { (event) => setCity(event.target.value)}
						required 
						required
					/>
					<Form.Label> State </Form.Label>
					<Form.Control 
						value = { state }
						onChange = { (event) => setState(event.target.value)}
						required 
						
					/>
					<Form.Label> Zipcode </Form.Label>
					<Form.Control 
						value = { zipcode }
						onChange = { (event) => setZipcode(event.target.value)}
						required 
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button type= "submit"> Add </Button>
				</Modal.Footer>
				</Form>
			</Modal>
		</>
	)

}