import * as server from "../util/getterServer";

import {
    reduceInstallation, reduceAssembly,
    reduceOrientation, reduceAirgap, reduceUSC
} from "./approvedApp"
import { reducePipesize, reducePhysicalSeparation} from "./ag"
import {
    reduceCheck1, reduceCheck2, reduceCheck1Tight,reduceCheck1Leaked,
    reduceCheck2Tight,reduceCheck2Leaked,reduceFinalCheck1, reduceTypeII ,
    reduceFinalCheck2,reduceFinalCheck1Tight,reduceFinalCheck1Leaked,
    reduceFinalCheck2Tight,reduceFinalCheck2Leaked
} from "./dc"

import {reduceFinalPass, reduceFinalFail} from "./finalResults"
import {reducePass,reduceFail,reduceRestored} from "./initialPassFailApp"
import {
    reduceDisc, reduceSpring, reduceGuide, reduceDiaph, reduceSeat, reduceOring, reduceModule, reduceRubberKit, reduceAiDisc,
    reduceAiSpring, reduceCheckSpring, reduceCheckDisc,reduceFloat
} from "./parts"

import {
    reduceSO1, reduceSO2, reduceUpstream,reduceSO1Rusted, reduceSO2Rusted,reduceUpstreamRusted, reduceComment
} from "./remarksApp"

import {reduceRemoved, reduceLines, reduceNewSerial} from "./removed"

import {reducePressdrop,reduceOpenedAt,reduceDidNotOpen,reduceRpTypeII, 
    reduceFinalPressdrop,reduceFinalOpenedAt,reduceFinalDidNotOpen
} from "./rp"

import {reduceSystemPSI, reduceDMR} from "./systemApp"

import {
    reduceOpenedAt as reduceAIOpenedAt,
    reduceDidNotOpen as reduceAIDidNotOpen, reduceCheckValve, reduceOpenedFully, reduceCheckFailed,
    reduceFinalOpenedAt as reduceAIFinalOpenedAt, reduceFinalDidNotOpen as reduceFinalAIDidNotOpen, 
    reduceFinalOpenedFully, reduceFinalCheckFailed, reduceFinalCheckValve
} from "./xvba"
import {
    reduceSerial, reduceModel, reduceMake, reduceSize, reduceLocation, reduceHazard, reduceType, reduceTestType
} from "./assemblyApp"

export const requestAssembly = (assemblyID) => dispatch => {
    return new Promise(async (resolve, reject) => {
        const response = await server.getAssembly(assemblyID)
        const data = await response.json()
        if (response.ok) {
            let obj = JSON.parse(data).obj[0]
            console.log("DATA", obj[0])
            let arr = []
            arr.push(dispatch(reduceSerial(obj.serial_number)))
            arr.push(dispatch(reduceModel(obj.model)))
            arr.push(dispatch(reduceMake(obj.make)))
            arr.push(dispatch(reduceSize(obj.size)))
            arr.push(dispatch(reduceLocation(obj.location)))
            arr.push(dispatch(reduceHazard(obj.hazard_type)))
            arr.push(dispatch(reduceType(obj.type)))
            Promise.all(arr).then((data, err) => {
                resolve()
            })
        }
    })
}

export const requestReport = (reportID) => dispatch => {
    return new Promise( async(resolve, reject) => {
        const response = await server.getReport(reportID)
        const data = await response.json() 
        if (response.ok) {
            
            let obj = JSON.parse(data).obj[0]
            console.log("DATA", obj[0])
            let arr = []

            arr.push(dispatch(reduceTestType(obj.serviceType)))
            arr.push ( dispatch(reduceInstallation(obj.approved_installation)) )
            arr.push(dispatch(reduceAssembly(obj.approved_assembly)) )
            arr.push(dispatch(reduceOrientation(obj.approved_orientation))) 
            arr.push(dispatch(reduceAirgap(obj.approved_airgap)))
            arr.push(dispatch(reduceUSC(obj.approved_USC)) )
            arr.push( dispatch(reducePipesize(obj.pipesize)))
            arr.push(dispatch(reducePhysicalSeparation(obj.physical_separation)))

            arr.push(dispatch(reduceCheck1(obj.RPA1_psid)))
            arr.push(dispatch(reduceCheck2(obj.RPA2_psid)))
            arr.push(dispatch(reduceCheck1Tight(obj.RPA1_tight)))
            arr.push(dispatch(reduceCheck1Leaked(obj.RPA1_leaked)))
            arr.push(dispatch(reduceCheck2Tight(obj.RPA2_tight)))
            arr.push(dispatch(reduceCheck2Leaked(obj.RPA2_leaked)))
            arr.push(dispatch( reduceTypeII(obj.RPA_typeII) ))

            arr.push(dispatch(reduceFinalCheck1(obj.repair_rpa_double_c1_psid)))
            arr.push(dispatch(reduceFinalCheck2(obj.repair_rpa_double_c2_psid)))
            arr.push(dispatch(reduceFinalCheck1Tight(obj.repair_rpa_double_c1_tight)))
            arr.push(dispatch(reduceFinalCheck1Leaked(!obj.repair_rpa_double_c1_leaked)))
            arr.push(dispatch(reduceFinalCheck2Tight(obj.repair_rpa_double_c2_tight)))
            arr.push(dispatch(reduceFinalCheck2Leaked(!obj.repair_rpa_double_c2_tight)))

            arr.push(dispatch(reduceFinalPass(obj.repair_pass)))
            arr.push(dispatch(reduceFinalFail(obj.repair_fail)))
            arr.push(dispatch(reducePass(obj.initialTest_pass)))
            arr.push(dispatch(reduceFail(obj.initialTest_fail)))
            arr.push(dispatch(reduceRestored(obj.restored)))

            arr.push(dispatch(reduceDisc(obj.disc)))
            arr.push(dispatch(reduceAiDisc(obj.air_disc)))
            arr.push(dispatch(reduceAiSpring(obj.air_spring)))
            arr.push(dispatch(reduceCheckDisc(obj.check_disc)))
            arr.push(dispatch(reduceCheckSpring(obj.check_spring)))
            arr.push(dispatch(reduceDiaph(obj.diaphragm)))
            arr.push(dispatch(reduceFloat(obj.ff)))
            arr.push(dispatch(reduceGuide(obj.guide)))
            arr.push(dispatch(reduceModule(obj.module)))
            arr.push(dispatch(reduceOring(obj.o_ring)))
            arr.push(dispatch(reduceRubberKit(obj.rubber_kit)))
            arr.push(dispatch(reduceSeat(obj.seat)))
            arr.push(dispatch(reduceSpring(obj.spring)))

            arr.push(dispatch(reduceSO1(obj.so1)))
            arr.push(dispatch(reduceSO2(obj.so2)))
            arr.push(dispatch(reduceUpstream(obj.upstream)))
            arr.push(dispatch(reduceSO1Rusted(obj.so1_rusted)))
            arr.push(dispatch(reduceSO2Rusted(obj.so2_rusted)))
            arr.push(dispatch(reduceUpstreamRusted(obj.upstream_rusted)))
            arr.push(dispatch(reduceComment(obj.comments)))

            arr.push(dispatch(reduceRemoved(obj.removed)))
            arr.push(dispatch(reduceLines(obj.lines)))
            arr.push(dispatch(reduceNewSerial(obj.new_serial)))

            arr.push(dispatch(reduceSystemPSI(obj.initialTest_system_psid)))
            arr.push(dispatch(reduceDMR(obj.initialTest_dmr)))

            arr.push(dispatch(reducePressdrop(obj.RPA_pressdrop)))
            arr.push(dispatch(reduceOpenedAt(obj.RPA_opened_at)))
            arr.push(dispatch(reduceDidNotOpen(obj.RPA_did_not_open)))
            arr.push(dispatch(reduceRpTypeII(obj.rp_typeII)))
            arr.push(dispatch(reduceFinalPressdrop(obj.repair_rpa_pressdrop)))
            arr.push(dispatch(reduceFinalOpenedAt(obj.repair_rpa_openedat)))
            arr.push(dispatch(reduceFinalDidNotOpen(obj.repair_rpa_did_not_open)))

            arr.push(dispatch(reduceAIOpenedAt(obj.VB_air_opened_at)))
            arr.push(dispatch(reduceAIDidNotOpen(obj.VB_air_didNotOpen)))
            arr.push(dispatch(reduceCheckValve(obj.VB_check_pressdrop)))
            arr.push(dispatch(reduceOpenedFully(obj.VB_air_opened_fully)))
            arr.push(dispatch(reduceCheckFailed(obj.VB_check_failed)))

            arr.push(dispatch(reduceAIFinalOpenedAt(obj.repair_vb_air_opened_at)))
            arr.push(dispatch(reduceFinalCheckValve(obj.repair_vb_check_pressdrop)))
            arr.push(dispatch(reduceFinalAIDidNotOpen(obj.repair_rpa_did_not_open)))
            arr.push(dispatch(reduceFinalOpenedFully(obj.repair_vb_air_opened_fully)))
            arr.push(dispatch(reduceFinalCheckFailed(obj.repair_vb_check_failed)))

            Promise.all(arr).then((data, err) => {
                resolve()
            })
        }   
    })
}

