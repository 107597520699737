import {
    SET_ASSEMBLY_LOCATION, CLEAR_ASSEMBLY_LOCATION
} from "../../actions/assemblyApp";

const _null = null;

export default (state = _null, { type, location }) => {
    Object.freeze(state);
    switch (type) {
        case SET_ASSEMBLY_LOCATION:
            return location;
        case CLEAR_ASSEMBLY_LOCATION:
            return _null
        default:
            return state;
    }
};