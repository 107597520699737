import {
  SET_END_POINT, CLEAR_END_POINT
} from "../../actions/map";


const _null = null; 
             
export default (state = _null, { type, obj}) => {
  Object.freeze(state);
  switch (type) {
    case SET_END_POINT:
      return obj;
    case CLEAR_END_POINT: 
      return _null; 
    case '':
      return _null
    default:
      return state;
  }
};