import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import InputGroup from 'react-bootstrap/InputGroup'
import Badge from 'react-bootstrap/Badge'
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	reduceFinalPressdrop,
	reduceFinalOpenedAt,
	reduceFinalDidNotOpen
} from "../../../actions/rp"
import {
	reduceFinalCheck1,
	reduceFinalCheck2Tight,
	reduceFinalCheck2Leaked,
} from "../../../actions/dc"
import {
	reduceTarget
} from "../../../actions/NumberPad"


export default function ReliefValve() {
	const dispatch = useDispatch()
	const opened_at = useSelector((state) => state.final_rp_opened_at)
	const pressdrop = useSelector((state) => state.final_pressdrop)
	const did_not_open = useSelector((state) => state.rp_final_did_not_open)
	const check2_tight = useSelector((state) => state.final_check2_tight)
	const check2_leaked = useSelector((state) => state.final_check2_leaked)


	useEffect(() => {
		if (pressdrop != null) {
			dispatch(reduceFinalCheck1(pressdrop))
		}
	}, [pressdrop])

	return (
		<Container>
			<Row>
				<Col>
					<strong> Relief Valve </strong>
				</Col>
			</Row>
			<Row>
				<Col>
					<InputGroup>
						<InputGroup.Text>
							Pressdrop
						</InputGroup.Text>
						<Form.Control
							value={pressdrop}
							readOnly
							onFocus={
								(event) => {
									dispatch(reduceTarget(
										{
											name: "Final PRESSDROP",
											slice: reduceFinalPressdrop,
											target: event.target, 
											current: pressdrop
										}
									))
								}
							}
						/>
					</InputGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<InputGroup>
						<InputGroup.Text>
							Opened At
						</InputGroup.Text>
						<Form.Control
							value={opened_at}
							readOnly
							onFocus={
								(event) => {
									dispatch(reduceTarget(
										{
											name: "FINAL_RP_OPENED",
											slice: reduceFinalOpenedAt,
											target: event.target,
											current: opened_at
										}
									))
								}
							}

						/>
					</InputGroup>
				</Col>

			</Row>
			<Row>
				<Col>
					<Form.Check
						label="Did not open"
						checked={did_not_open}
						onChange={
							(event) => {
								dispatch(reduceFinalDidNotOpen(event.target.checked))
							}
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Label> Check #2 </Form.Label>
				</Col>
				<Col>
					<Form.Check
						type="checkbox"
						label="Tight"
						checked={check2_tight}
						onChange={
							(event) => {
								dispatch(reduceFinalCheck2Tight(event.target.checked))
								dispatch(reduceFinalCheck2Leaked(!event.target.checked))
							}
						}
					/>
				</Col>
			</Row>
		</Container>
	)
}