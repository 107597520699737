import {
    SET_ASSEMBLY_TYPE, CLEAR_ASSEMBLY_TYPE
} from "../../actions/assemblyApp";

const _null = null;

export default (state = _null, { type, devicetype }) => {
    Object.freeze(state);
    switch (type) {
        case SET_ASSEMBLY_TYPE:
            return devicetype;
        case CLEAR_ASSEMBLY_TYPE:
            return _null
        default:
            return state;
    }
};