export const addTesterAddress=  (obj) => (
	fetch("backflow/settings/add", {
		method: "PUT",
		body: JSON.stringify( { obj: obj}),
		headers: {
			"Content-Type": "application/json"
		}
	})
);
export const removeTesterAddress=  (id) => (
	fetch("backflow/settings/remove", {
		method: "DELETE",
		body: JSON.stringify( { id: id}),
		headers: {
			"Content-Type": "application/json"
		}
	})
);

export const getTesterAddressList=  () => (
	fetch("backflow/settings/addresslist" , {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
);


export const setDefaultAddress = (addressID) => (
	fetch("backflow/settings/makeDefault", {
		method: "PUT",
		body: JSON.stringify( { addressID : addressID}),
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const requestDefaultAddress = () =>(
	fetch("backflow/settings/defaultAddress" , {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
) 