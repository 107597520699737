import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { addSeconds,isBefore,
     isAfter, addMilliseconds, 
     differenceInSeconds, format} from "date-fns"

import { reduceOrder, reduceRouted} from "../../actions/map"

class MinHeap {
    constructor() {
        this.heap = [];
    }

    push(element) {
        this.heap.push(element);
        this._heapifyUp();
    }

    pop() {
        const root = this.heap[0];
        const last = this.heap.pop();
        if (this.heap.length > 0) {
            this.heap[0] = last;
            this._heapifyDown();
        }
        return root;
    }

    isEmpty() {
        return this.heap.length === 0;
    }

    _heapifyUp() {
        let index = this.heap.length - 1;
        while (index > 0) {
            const parentIndex = Math.floor((index - 1) / 2);
            if (this.heap[index].drivingtime >= this.heap[parentIndex].drivingtime) break;
            [this.heap[index], this.heap[parentIndex]] = [this.heap[parentIndex], this.heap[index]];
            index = parentIndex;
        }
    }

    _heapifyDown() {
        let index = 0;
        while (index < this.heap.length) {
            const leftChild = 2 * index + 1;
            const rightChild = 2 * index + 2;
            let smallest = index;

            if (leftChild < this.heap.length && this.heap[leftChild].drivingtime < this.heap[smallest].drivingtime) {
                smallest = leftChild;
            }
            if (rightChild < this.heap.length && this.heap[rightChild].drivingtime < this.heap[smallest].drivingtime) {
                smallest = rightChild;
            }
            if (smallest === index) break;

            [this.heap[index], this.heap[smallest]] = [this.heap[smallest], this.heap[index]];
            index = smallest;
        }
    }
}

export default function Timed(props) {
    const [myPath, setMyPath] = useState([])
    const [arrivals, setArrivals] = useState([])
    const date = useSelector((state) => state.dispatchDate)
    const g = useSelector((state) => state.distance_matrix)
    const timeWindows = useSelector((state) => state.time_windows ) 
    const stoplist = useSelector((state) => state.stoplist)
    const dispatch = useDispatch() 
    const times = useSelector((state) => state.services_times )

    useEffect(()=>{
        setArrivals([])
        setMyPath([])
    }, [date])

    {/*const timeWindows = [
        { start: `${date}T07:00:00`, end:`${date}T16:00:00`}, 
        { start: `${date}T07:00:00`, end: `${date}T16:00:00`},
        { start: `${date}T07:00:00`, end: `${date}T16::00`},  
        { start:`${date}T07:00:00`, end: `${date}T16:00:00`},
        { start:`${date}T07:00:00`, end:`${date}T16:00:00`}, 
        { start:`${date}T07:00:00`, end:`${date}T16:00:00`},
        
    ];*/}
    const startNode = 0;

   function getServiceTime(node){
     return 30*30; 
    }


    function isWithinWindow(arrivalTime, node){
        console.log("arrivalTime: ", arrivalTime)
        console.log("start: ",  timeWindows[node].start  ,"end:", timeWindows[node].end)

        let startTimeFlag = false;    
        let endTimeFlag = false;

       

        if(isBefore(arrivalTime, new Date( timeWindows[node].end ))){
            console.log("before end time")
            endTimeFlag = true;
        }
        let ff = startTimeFlag && endTimeFlag;
        console.log("end flag: ", ff)
        return ( ff)
    }


    const generateroutes = () => {

       // console.log("Starting route generation")
        const pq = new MinHeap(); // Min-Heap based on driving time
        const drivingTime = {}; // Stores the earliest arrival time at each node
        const previousNode = []; // Keeps track of the path
        const arrivalTime = [];  // keeps trach of arrival times
        const paths = []; // Keeps track of all possible paths
        const visited = new Array(g.length).fill(false); 

        // Initialize all nodes with infinity arrival time, except the start node
        for (let node = 0; node < g.length; node++) {
            drivingTime[node] = Infinity; // Use Infinity for time comparisons
            previousNode[node] = null;
            arrivalTime[node] = Infinity; 
            paths[node] = [];
        }
       // console.log("Arrays: ", drivingTime,   previousNode, visited)

        // push in the first node with initial values of 0 and starting time 
        drivingTime[startNode] = 0;
        arrivalTime[startNode] = new Date(`${date}T07:30:00`); 
        pq.push({ node: startNode, 
            drivingtime: drivingTime[startNode],
            eta: new Date(`${date}T07:30:00`),   
            path: [startNode]
        });

        while (!pq.isEmpty()) {
            const { node, drivingtime, eta, path} = pq.pop();
            if( visited[node] ) continue; 
            // Process neighbors
            const neighbors = g[node];
            //console.log("These are the neighbors: ", neighbors)
            let neighbors_pq = new MinHeap()

            for(let i = 0; i < neighbors.length; i++){
                if(!visited[i]){
                    neighbors_pq.push({ drivingtime: neighbors[i], neighbor: i}) 
                }
            }

            if (visited[node] && path.includes(node)) continue;

           // console.log(`this is the neighbor priority queue: `, neighbors_pq)
            let found = false
            let next = -1
            let tTime = 0; 
            let ETA = null; 
            let waitingTime = 0

            while(!found && !neighbors_pq.isEmpty() ){
                //check for time windows and pop shortest driving time stop
                const {drivingtime, neighbor} = neighbors_pq.pop()
                //console.log("processing: ", neighbor," as v with time ", drivingtime )
               // console.log("trying this neighbor: ", neighbor)
                if(neighbor == node ) continue; 
                let departureTime = addSeconds(eta, getServiceTime(node) );
                let arrivalTime = addSeconds( departureTime, drivingtime)

                if( isBefore(arrivalTime, new Date( timeWindows[neighbor].start )  ) ){
                    waitingTime = differenceInSeconds(  new Date( timeWindows[neighbor].start), arrivalTime )
                    arrivalTime =  new Date( timeWindows[neighbor].start )  
                }
            
                if(isAfter(arrivalTime, new Date( timeWindows[neighbor].end ))){
                    //console.log("arrival time is after end time")
                    continue; 
                }


                if( !visited[neighbor]) {
                   //console.log(`assigning next and time: ${drivingtime}, ${neighbor}`)
                    next = neighbor;  
                    found = true; 
                    tTime = drivingtime;
                    ETA = arrivalTime
                }
            }

            if( found ){
                //console.log(`Best choice found: ${next} ${tTime} with waiting time of: ${waitingTime / 60}` )
                previousNode[next] = node 
                visited[node] = true
                let departureTime = addSeconds(eta, getServiceTime(-1) );
                arrivalTime[next] = addSeconds( departureTime, tTime ) 
                drivingTime[next] = { seconds: tTime, minutes: tTime/60};  
                const newPath = [...path, next];
                pq.push({ node: next, drivingtime: tTime, eta: arrivalTime[next], path: newPath  });
                paths[next].push(newPath)
            }else{
               // console.log("could not find best next option ", next) 
            }
        }
        let validPathFound = false
        for(let i = 0; i < paths.length; i++){
            const nodePaths = paths[i];
            for(let j = 0; j < nodePaths.length; j++){
                const currentPath = nodePaths[j]
               // console.log("This is a possible path ", paths[i][j].length, g.length)
                let score = 0; 
                //check path validity 
                if( paths[i][j].length == g.length){    
                    setMyPath(paths[i][j])
                    validPathFound = true
                    break; 
                }
            }
            console.log("These are the paths found: ", nodePaths)
            if(validPathFound) break; 
        } 
        if(!validPathFound){
            //default to non routed or routed but disregard time windows 
            dispatch( reduceRouted(false) )
            let unroutedPath = []
            let defaultArrivals = []
            for(let i = 0; i < g.length ; i ++){
                unroutedPath.push(i); 
                defaultArrivals.push( new Date(`${date}T07:30:00`) )
            }
            setMyPath( unroutedPath )
            setArrivals(defaultArrivals)
        }else{
            console.log("Not valid path found")
            dispatch( reduceRouted(true) )
            setArrivals(arrivalTime)  
        }
    }

    useEffect(()=>{
        if(g != null && timeWindows != null){
            //console.log("graph and windows listener: ", g, timeWindows)
            console.log("Generating route", g)
            generateroutes()
        }
    }, [g, timeWindows])


    useEffect(()=>{
        if(myPath.length > 0 ){
         console.log("This is the path: ", myPath)
           dispatch ( reduceOrder(myPath) ) 

        }
    }, [myPath])

    useEffect(()=>{
        if(arrivals.length > 0 ){
         //   console.log( "These are the arrival times: ", arrivals)
        }
    }, [arrivals])

    const renderPath =  myPath
        .map( (node, index) => {
           // console.log("This is a node: ", node)
            return(
                <>
                    <p> {node} -> {index} </p>
                </>
            )
    })

    const renderServiceTimes = times.map( (tt, index) =>{
        return(
            <p> {tt /60 } mins,{index} </p>

        )

    })

    const renderArrivals = arrivals.map( (dd, index) =>{
        if(dd && times.length > 0){
            return(
             <tr>
                <td>
                    {index}  
                </td>
                <td>
                    {format( dd, "HH:mm aaaa")}
                </td>
            </tr>
            )
        }else{
            return(
                <> </>
            )

        }
        
    })

    return (
        <>
           {/* <Table>
                <thead>
                    <tr>
                        <th> stop # </th>
                        <th> ETA </th>
                        <th> Service time </th>
                    </tr>
                </thead>
                <tbody>
                    {renderArrivals}
                </tbody>
            </Table>*/}
        </> 
    )
}



