import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button'
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import NewAddress from "./NewAddress"
import {
    addTesterAddress, 
    removeTesterAddress, 
    getTesterAddressList, setDftAddress, requestDefaultAddress
} from "../../actions/settings"


function LineItem(props) {
	const dispatch = useDispatch() 
	const [defaultAddress, setDefaultAddress] = useState(null)
	const [isDefault, setIsDefault] = useState(false)
	const [loader, setLoader] = useState(false) 

	useEffect(()=>{
		setIsDefault(props.item.isDefault )
	}, [props.item.isDefault])

	const makeDefault = (event) =>{
		console.log("Making this address, tester's default" , props.item.id)
		setLoader(true)
		setDftAddress(props.item.id).then((data, err) =>{
			setIsDefault(true)
			dispatch( requestDefaultAddress() ).then((data, err) =>{
				setLoader(false)
			})
		})
	}

	return(
			<>
				<ListGroup.Item>

					<Container>
						<Row>
							<Col>
								<Form.Check 
									type = "radio"
									name = "defaultAddress" 
									onClick ={ makeDefault } 
									checked = {isDefault}
								/>
							</Col>
							<Col xs= {8} sm = {5}>{props.item.label} { props.item.street} {props.item.city} {props.item.state} {props.item.zipcode}</Col>
							<Col>
								<Button 
									size = "sm" 
									variant = "secondary"
									onClick = { () => {
										removeTesterAddress( props.item.id ).then((data, err) =>{
											dispatch( getTesterAddressList() ).then((data, err) =>{

											})
										})
									}} 
								>
									 - 
								</Button> 
							</Col>
						</Row>
					</Container>
				</ListGroup.Item>

			</>
			
		)

}

export default function Settings(){
	const list = useSelector((state) => state.tester_addresses)
	const [add, setAdd] = useState(false)
	const dfAddress = useSelector((state) => state.default_address)
	const dispatch = useDispatch() 
	useEffect(()=>{
		dispatch( getTesterAddressList() ).then((data, err) =>{

		})
	}, [dispatch])

	const renderlist = list.map((item) =>{
		return(
			<LineItem item = {item} />
		)
	})

	const closeme  = ( ) => setAdd(false)

	return(
		<Container>
			{
				add ?
					<NewAddress closeModal = { closeme }  />
				: 
					<>
					</>

			}
			<br/>
			<Row>
				<Col xs = {8}>
					Address List <br/>
					 <small>These addresses are used as starting and ending points for routing purposes </small>
				</Col>
				<Col>	
					<Button 
						variant ="secondary"
						onClick = {
							()=>{ setAdd(true)}


						}
					> New Address </Button>
				</Col>
			</Row>
			<br/>
			<Row>
				<Col>
					<ListGroup>
						{renderlist}
					</ListGroup>
				</Col>
			</Row>
			
		</Container>
	)
}