import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import {
    reducePass,
    reduceFail,
    reduceRestored
} from "../../../actions/initialPassFailApp"


export default function PassFail() {
    const dispatch = useDispatch(); 
    const pass = useSelector((state) => state.initial_pass)
    const fail = useSelector((state) => state.initial_fail)
    const [na, setNa] = useState( false )
    const restored = useSelector((state) => state.restored)

    return (
        <Container>
            <Row>
                <Col>
                    <strong> System </strong>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check label="PASSED"
                        type = "radio"
                        checked={pass}
                        onChange={
                            (event) => {
                                dispatch(reducePass(event.target.checked))
                                dispatch(reduceFail(!event.target.checked))
                                setNa(false)
                            }
                        }
                        
                    />
                    <Form.Check
                        label="FAILED"
                        type = "radio"
                        checked={fail}
                        onChange={
                            (event) => {
                                dispatch(reduceFail(event.target.checked))
                                dispatch(reducePass(!event.target.checked))
                                setNa(false)
                            }
                        }

                    />
                    <Form.Check
                        label="NA"
                        type="radio"
                        checked={na}
                        onChange={
                            (event) => {
                                dispatch(reduceFail(false))
                                dispatch(reducePass(false))
                                setNa(true)
                            }
                        }

                    />
                    <hr/>
                    <Form.Check
                        label="System Restored?"
                        checked={restored}
                        onChange={
                            (event) => {
                                dispatch(reduceRestored(event.target.checked))
                            }
                        }

                    />
                </Col>
            </Row>
        </Container>
    )
}