import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Badge from 'react-bootstrap/Badge'
import InputGroup from 'react-bootstrap/InputGroup'

import { useSelector, useDispatch } from "react-redux";
import {
	reducePipesize, reducePhysicalSeparation
} from "../../../actions/ag"
import {
	reduceTarget
} from "../../../actions/NumberPad"

export default function Airgap() {
	const dispatch = useDispatch();
	const pipesize = useSelector((state) => state.pipesize)
	const physical_separation = useSelector((state) => state.physical_separation)

	return (
		<Container>
			<Row>
				<Col>
					<strong> Airgap </strong>
				</Col>

			</Row>

			<Row>
				<Col>
					<InputGroup size="sm">
						<InputGroup.Text>Pipesize</InputGroup.Text>
						<Form.Control
							value={pipesize}
							readOnly
							onFocus={
								(event) => {
									dispatch(reduceTarget(
										{
											name: "pipesize",
											slice: reducePipesize,
											target: event.target
										}
									))
								}
							}
						/>
						<InputGroup.Text>inches</InputGroup.Text>
					</InputGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<InputGroup size="sm">
						<InputGroup.Text>Physical Separation</InputGroup.Text>
						<Form.Control
							value={physical_separation}
							readOnly
							onFocus={
								(event) => {
									dispatch(reduceTarget(
										{
											name: "physical-separation",
											slice: reducePhysicalSeparation,
											target: event.target
										}
									))
								}
							}
						/>
						<InputGroup.Text>inches</InputGroup.Text>
					</InputGroup>
				</Col>
			</Row>

		</Container>
	)
}