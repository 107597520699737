import {
    SET_AIRGAP, CLEAR_AIRGAP
} from "../../actions/approvedApp";

const _null = null;

export default (state = _null, { type, truefalse }) => {
    Object.freeze(state);
    switch (type) {
        case SET_AIRGAP:
            return truefalse;
        case CLEAR_AIRGAP:
            return _null
        default:
            return state;
    }
};