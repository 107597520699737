import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./Pages/Login"; 
import LandingPage from "./Pages/LandingPage"; 
import SimpledDown from "./secondAlternative/SimpledDown"
import AlternativeLanding from "./Pages/AlternativeLanding"

import ActivateAccount from "./Pages/ActivateAccount"; 
import { useState, useEffect, useRef, useCallback} from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import ProtectedRoutes from "./util/ProtectedRoutes"; 
import AuthRoutes from "./util/AuthRoutes"; 
import "./styles.css";



function App() {
  return (
  
       <BrowserRouter>

        <Routes>
          <Route element={<ProtectedRoutes />}>
            <Route path="/tests" element={ <AlternativeLanding />} />
              <Route path="/activateAccount" element = { <ActivateAccount />} />
          </Route>
          <Route element={ <AuthRoutes/>} >
            <Route path="/testerLogin" element={<Login />} />
             <Route path="" element={ <Login />} />
             
             <Route path="/login" element={ <Login />} />

          </Route>
          <Route path = "/simple" element={ <SimpledDown />} />
        </Routes>
      </BrowserRouter>
  
  );
}

export default App;
