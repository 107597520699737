import {AdvancedMarker, APIProvider,
 Map, useMap, useMapsLibrary, Pin} from '@vis.gl/react-google-maps';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Accordion from 'react-bootstrap/Accordion'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reduceWaypoints, reduceRoute, reduceLegs, clearStart} from "../../actions/map"
import { requestStoplist, requestAddressDetails, requestStopsRoute } from "../../actions/stopsApp"
import StartEnd from "./start_end"
import {
    getTesterAddressList
} from "../../actions/settings"
import Route from "./Route"
import DistanceMatrix from "./DistanceMatrix"
import Cal from "./Cal"


const Geo = (props) => {
  const geocodingLib = useMapsLibrary('geocoding');
  const [myComponent, setMyComponent] = useState(<> </>) 
  const waypoints = useSelector((state) => state.waypoints)
  const list  = useSelector((state)=> state.stoplist)
  const dispatch = useDispatch()
  const start = useSelector((state) => state.startpoint)
  const end = useSelector( (state) => state.endpoint)
  const [lat, setLat] = useState(null)
  const [long, setLong] = useState(null)
  const [myAddress, setMyAddress] = useState(null)
  const date = useSelector((state) => state.dispatchDate)

  useEffect(()=>{
    dispatch( clearStart() )
  }, [date])

  useEffect(()=>{
    //load up stop address details
    if (props.stop.addressID != null) {
      requestAddressDetails(props.stop.addressID).then((data, err) => {
        setMyAddress(data)
      })
    }
  },[])

  const getLogAlt = () =>{
    return new Promise( async(resolve, reject) =>{
      let geod = await geocoder.geocode( { address: `${myAddress.street}, ${myAddress.city}, myAddress.state, myAddress.zipcode`})
      setLat(geod.results[0].geometry.location.lat() )
      setLong(geod.results[0].geometry.location.lng() )
      resolve()
    })
  }

  useEffect(()=>{
    if(lat && long ){
      let temp = {}
      temp.location  = { lat: lat, lng: long}
      console.log("address, alt, lgn", myAddress.street, lat, long)
      let temparr = [...waypoints]; 
      temparr.push(temp)
      dispatch(reduceWaypoints( temparr ) ).then((data, err) =>{

      })

      setMyComponent( <AdvancedMarker
         position= { {lat: lat, lng: long} } 
         title = { "Title" }
         label = {myAddress.location_name}
         >
          <Pin   
            background={'red'} borderColor={'red'} scale={0.2} >   
             <p><small> </small> </p>
         
          </Pin>
        </AdvancedMarker>
          ) 
    }
  }, [lat, long])



  const geocoder = useMemo(
    () => geocodingLib && new geocodingLib.Geocoder(),
    [geocodingLib]
  );

  useEffect(() => {
    if (!geocoder) return;
    if(myAddress != null){
      console.log("Getting way point for: ", myAddress.street)
       getLogAlt() 
    }
  }, [geocoder, myAddress]);
  return (
      <>
        {myComponent}
      </>
    )
};


function MapApp() {
  const routerLib = useMapsLibrary('routes');
  const geocodingLib = useMapsLibrary('geocoding');
  const map = useMap("mymap")

  const list = useSelector((state) => state.stoplist)
  const [googleReady, setGoogleReady] = useState(false) 
  const [hasStops, setHasStops] = useState(false)
  const [stopsLoading, setStopsLoading] = useState(false)

  const mydate = useSelector((state) => state.dispatchDate)
  const addresslist = useSelector((state) => state.stoplist)
  const position = {lat: 45.4943234, lng: -122.6048274 };
  const [path, setPath] = useState(null) 
  const dispatch = useDispatch();
  const waypoints = useSelector((state) =>state.waypoints)
  const ttAddressList = useSelector((state) => state.tester_addresses )
  const start = useSelector((state) => state.startpoint)
  const end = useSelector((state) => state.endpoint)
  const [mapLoader, setMapLoader] = useState(false)


  useEffect(() => {
    if (list.length > 0) {
      setHasStops(true)
    } else {
      setHasStops(false)
    }
  }, [list])

  useEffect(() => {
    //request date stops
    if (mydate != null) {
      setStopsLoading(true)
      dispatch(reduceWaypoints( [] ) ).then((data, err) =>{
      })
      dispatch(requestStoplist(mydate)).then((data, err) => {
        setStopsLoading(false)
      })
    }
  }, [mydate])

  const renderPins = list.map((addr) =>{
    return(
      <Geo  stop= {addr} />
    )

  }) 
  let maps = (
          <Map defaultCenter={position} defaultZoom={8} mapId="DEMO_MAP_ID" id = "mymap">
          </Map>)

 
  return (
      <Container fluid>
   
         <Row>
            <Col>
              <APIProvider apiKey={'AIzaSyAeSZ5OFu_Kmua5qJB_9FzJBiSjQk7kxcY'}>
                  <DistanceMatrix />
                  <Cal />
              </APIProvider>
            </Col>

          </Row> 

      </Container>
  );
}

export default MapApp;