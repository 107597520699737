import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Badge from 'react-bootstrap/Badge'
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    reduceSystemPSI,
    reduceDMR,
} from "../../../actions/systemApp"

import {
    reduceTarget
} from "../../../actions/NumberPad"


function Editable() {
    const dispatch = useDispatch();
    const dmr = useSelector((state) => state.system_dmr)
    const psi = useSelector( (state) => state.system_psi)
    const type = useSelector ((state) => state.assembly_type)
    const [showDMR, setShowDMR] = useState(true)

    useEffect(()=>{
        if( type == "AVB" || type == "SVB" || type == "PVB"){
            setShowDMR(false)
        }
    }, [type])


    return (
        <>
            
                    <InputGroup size= "sm">
                        <InputGroup.Text> System PSI </InputGroup.Text>
                        <Form.Control
                            value={psi}
                            readOnly
                            onFocus={
                                (event) => {
                                    dispatch(reduceTarget(
                                        {
                                            name: "PSI",
                                            slice: reduceSystemPSI,
                                            target: event.target
                                        }
                                    ))
                                }   
                            }
                        />
                        
                    </InputGroup>
                    
                {
                    showDMR ?
                        <>  
                            <InputGroup size= "sm">
                                <InputGroup.Text> DMR </InputGroup.Text>
                                     <Form.Control
                                        readOnly
                                        value={dmr}
                                        readOnly
                                        onFocus={
                                            (event) => {
                                                dispatch(reduceTarget(
                                                    {
                                                        name: "DMR",
                                                        slice: reduceDMR,
                                                        target: event.target
                                                    }
                                                ))
                                            }
                                        }
                                            />
                            </InputGroup>
             
                        </>
                    : 
                        <>
                        </>
                }
               
                  
         
        </>
     )
}




export default function System() {
    return (
        <Container fluid>
            
            <Row>
                <Col>
                    <Editable />
                </Col>
            </Row>
       

        </Container>
    )

}